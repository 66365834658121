import {Command} from 'prosemirror-state';
import {Fragment} from 'prosemirror-model';
import {insertNode} from './insertNode';

/**
 * Replaces the current selection with a new image node.
 *
 * @param state
 * @param dispatch
 */
export const insertImage: Command = (state, dispatch) => {
  const imageType = state.schema.nodes.image;
  const attrs = {
    src: '',
    alt: 'Image',
  };

  /*
   * Invalid Command if images are not supported.
   */
  if (!imageType) {
    return false;
  }

  return insertNode(imageType, attrs)(state, dispatch);
};
