import {MarkSpec} from 'prosemirror-model';

export let link = {
  attrs: {
    href: {},
    title: {default: null},
    openInNewTab: {default: true},
  },
  inclusive: false,
  parseDOM: [
    {
      tag: 'a[href]',
      getAttrs(dom: HTMLElement) {
        return {
          href: dom.getAttribute('href'),
          title: dom.getAttribute('title'),
          openInNewTab: dom.getAttribute('target') === '_blank',
        };
      },
    },
  ],
  toDOM(node) {
    let {href, title} = node.attrs;
    return [
      'a',
      {
        href,
        title,
        target: node.attrs.openInNewTab ? '_blank' : null,
        rel: node.attrs.openInNewTab ? 'noopener noreferrer' : null,
      },
      0,
    ];
  },
} as MarkSpec;
