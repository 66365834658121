<template>
  <Head
    :title="
      t('page.title', {
        course: assignment.course.code,
        assignment: assignment.name,
        taskNumber: taskNumber,
        firstName: student.firstName,
        lastName: student.lastName,
      })
    "
  />
  <div
    ref="containerRef"
    class="@container grow xl:flex-1 flex flex-col"
    :class="{
      'xl:min-h-auto': viewMode === 'scrolling',
      'xl:min-h-0': viewMode === 'sticky',
    }"
  >
    <!-- Sticky Header -->
    <div class="px-5 lg:px-7 pb-4 pt-3 md:pt-0 lg:pb-5">
      <student-navigation
        :assignment="assignment"
        v-model:current-section-id="currentSectionIdString"
        :course-sections="courseSections"
        :students="students"
        :student="student"
        :task-number="taskNumber"
      />
    </div>
    <!-- Content Panes -->
    <div
      class="grow xl:flex-1 lg:min-h-0 relative bg-white border-y border-gray-200 flex flex-col @2xl:flex-row overflow-hidden"
    >
      <!-- Task Pane -->
      <div
        class="relative w-fit flex flex-col @2xl:border-r @2xl:border-gray-200 h-full"
        :style="{
          minWidth: `${dividerPosition}%`,
        }"
      >
        <div
          v-if="response?.attachments?.length"
          class="px-5 lg:px-6 py-0 py-3.5 bg-gradient-to-b from-gray-50 via-gray-50 to-gray-100 border-b border-gray-300/30 shrink min-h-0"
        >
          <s-response-attachment-list
            :response-id="response.id"
            :attachments="response.attachments"
          ></s-response-attachment-list>
        </div>

        <div class="lg:overflow-y-auto flex-1">
          <div class="px-5 md:px-7 lg:px-8 py-4 md:py-6 lg:py-7">
            <legacy-task
              :seed="student.id"
              :task="task"
              :task-state="taskState"
              :rubric="taskRubric"
              :working-response-copy="response"
              :stored-response-id="response?.id"
              :stored-response-content="response?.content"
              :stored-response-is-draft="!!response?.isDraft"
              :stored-response-attachments="response?.attachments"
              :stored-response-feedback-by-parts="props.feedbackByPart"
              :stored-response-part-grades="props.partGrades"
              :show-random-variables="showRandomVariables"
              :can="{
                viewAnyPartGrade: true,
              }"
            />
          </div>
        </div>

        <div
          class="bg-gradient-to-b from-gray-50 via-white to-white border-t border-gray-150 border-t-gray-200"
        >
          <task-state-table
            :task-state="taskState"
            v-model:show-random-variables="showRandomVariables"
          />
        </div>

        <div
          class="px-5 md:px-7 pt-3 pb-4 bg-gradient-to-b from-gray-100 via-gray-50 to-gray-50 border-t border-gray-150 border-t-gray-200"
        >
          <div class="flex flex-col md:flex-row flex-wrap md:items-start justify-between gap-4">
            <response-info-area-marking
              :task="task"
              :assignment-tasks="assignmentTasks"
              :draft-task-ids="draftTaskIds"
              :response="response"
              :task-responses="taskResponses"
              v-model:selected-response="selectedResponse"
              :student-assignment-task-grades="studentAssignmentTaskGrades"
              :attempted-task-ids="attemptedTaskIds"
              :due-date="courseLikeAssignment?.dueDate"
              :assignment-extension="extension"
              :show-no-responses-badge="!maxAttempts"
              :max-attempts="maxAttempts"
              :additional-attempts="additionalAttempts"
              :num-task-attempts="numTaskAttempts"
              :no-more-attempts="noMoreAttempts"
              :assignment="assignment"
              :student="student"
            />
          </div>
        </div>
      </div>
      <!-- Resize Handle -->
      <div class="w-0 relative h-full flex flex-col items-center justify-center">
        <button
          class="group relative hidden @2xl:block z-10 top-0 h-full group w-3 flex items-center cursor-col-resize"
          :aria-label="t('resizeHandle')"
          ref="dragHandle"
          @click.alt="resetPaneSizes"
        >
          <div
            role="presentation"
            class="absolute -ml-px left-1/2 top-1/2 -translate-y-1/2 overflow-hidden py-2 pr-2 pointer-events-none"
          >
            <div
              class="bg-white rounded-r-full border border-gray-200 border-l-0 shadow-sm py-2 pr-1 pointer-events-auto"
            >
              <div
                class="rounded-lg w-px h-24 bg-gray-150 group-hover:bg-gray-300 transition"
              ></div>
            </div>
          </div>
          <div
            class="pointer-events-none group-active:pointer-events-auto absolute top-0 left-0 w-screen h-full bg-transparent -translate-x-1/2"
          ></div>
        </button>
        <div
          class="z-[5] absolute top-0 left-0 h-full w-3 bg-gradient-to-r from-black/70 via-black/20 to-transparent pointer-events-none opacity-5"
        ></div>
      </div>
      <!-- Feedback Pane -->
      <form class="relative bg-gray-50 flex flex-col w-full" @submit.prevent="submitGrade">
        <div class="z-0 absolute top-0 left-0 w-full h-32 flex flex-col overflow-hidden">
          <div
            class="z-10 h-[0.375rem]"
            :class="determineFeedbackBorderBackgroundClass(response, selectedGrade)"
          ></div>
          <div
            class="flex-1 bg-gradient-to-b"
            :class="determineFeedbackAreaBackgroundClass(response, selectedGrade)"
          ></div>
        </div>
        <!-- Sticky Header -->
        <div class="relative px-5 lg:px-7 pt-4 pb-3 border-b border-gray-400/15">
          <div class="flex flex-wrap items-center justify-between gap-3">
            <h2 class="flex-1 text-gray-800 flex flex-wrap items-center gap-x-1 leading-tight">
              <span class="font-bold whitespace-nowrap">{{ t('task') }} {{ taskNumber }}</span>
              <span v-if="attemptNumber" class="font-medium opacity-50">&ndash;</span>
              <span
                v-if="attemptNumber"
                class="flex items-center gap-x-3 font-medium opacity-70 whitespace-nowrap"
              >
                <span class="flex items-center gap-x-1">
                  <i18n-t keypath="responseXofY">
                    <template #number>
                      <strong class="text-gray-800">{{ attemptNumber }}</strong>
                    </template>
                    <template #total>
                      <strong class="text-gray-800">{{ taskResponses.length }}</strong>
                    </template>
                  </i18n-t>
                </span>
                <span
                  v-if="responseIsLate && courseLikeAssignment"
                  class="flex gap-x-0.5 items-center italic text-red-600 font-bold"
                >
                  <s-icon name="clock-alert" class="opacity-80" size="16" />
                  {{
                    formatDistance(getResponseTimestamp(response), courseLikeAssignment.dueDate, {
                      addSuffix: false,
                    })
                  }}
                  {{ t('late') }}
                </span>
              </span>
            </h2>
            <div class="flex flex-wrap gap-3 flex-row-reverse">
              <s-task-grade-pill
                v-if="selectedGrade"
                :grade="selectedGrade"
                :grade-display="assignment.course.gradeDisplay"
                :weight="taskWeight"
              />
              <s-badge v-if="response?.isDraft" class="self-end" color="gray">
                {{ t('draft') }}
              </s-badge>
              <s-badge v-else-if="notGraded" class="self-end" color="yellow">
                {{ t('notGraded') }}
              </s-badge>
              <s-badge v-if="response?.gradingStatus === 'failed'" color="red">
                <s-icon name="warning" size="16" class="opacity-70" />
                {{ t('autogradingFailed') }}
              </s-badge>
            </div>
          </div>
        </div>
        <!-- Scrolling Feedback Pane -->
        <div
          class="relative px-5 lg:px-7 py-5 flex-1 flex flex-col gap-5 lg:overflow-y-auto feedback-scroll-shadow"
        >
          <div class="relative flex flex-col gap-5 flex-1">
            <!-- Edit Grade + Feedback -->
            <div v-if="isEditingFeedback" class="stack vertical gap-4">
              <!-- @vue-ignore -->
              <s-input-field
                id="numerator"
                type="number"
                step="0.01"
                v-model="editFeedbackForm.numerator"
                :min="0"
                :max="taskWeight"
                :error="editFeedbackForm.errors.numerator"
                inputClass="!rounded-r-none"
              >
                <template #suffix>
                  <span class="text-gray-400 font-medium">/</span>
                  <span>
                    {{ t('xPoints', {count: taskWeight.toFixed(2)}, taskWeight) }}
                  </span>
                </template>
              </s-input-field>
              <s-textarea-field
                id="feedback"
                v-model="editFeedbackForm.reason"
                :error="editFeedbackForm.errors.reason"
                :label="t('feedback')"
              ></s-textarea-field>
            </div>

            <!-- Display Grade + Feedback -->
            <template v-else>
              <!-- Draft -->
              <div v-if="response?.isDraft" class="flex flex-col items-end">
                <div
                  class="w-full bg-gray-100 border-gray-150 text-gray-400 italic rounded-md font-medium text-base text-center leading-tight px-4 py-5 border-[1.5px] mb-5"
                >
                  {{ t('draftState') }}
                </div>
              </div>

              <!-- Not Submitted, Not Graded-->
              <div v-else-if="notSubmitted && notGraded" class="flex flex-col items-end">
                <div
                  class="w-full bg-gray-100 border-gray-150 text-gray-600 italic rounded-md font-medium text-base text-center leading-tight px-4 py-5 border-[1.5px] mb-5"
                >
                  {{ t('noResponse') }}
                </div>
              </div>

              <!-- Graded -->
              <feedback-by-part
                v-else-if="selectedFeedback"
                :feedback-by-part="selectedFeedback"
                :use-html-newlines="selectedGrade?.isManuallyEntered"
              />
            </template>
          </div>
        </div>

        <div v-if="showFeedbackTabs" class="overflow-hidden pb-4 -mb-4 relative flex w-full">
          <bottom-tab
            :label="t('manualFeedback')"
            icon="pencil"
            :active="!showOriginalFeedback"
            @click.prevent="showOriginalFeedback = false"
          />
          <bottom-tab
            :label="t('originalFeedback')"
            icon="creation"
            :active="showOriginalFeedback"
            @click.prevent="showOriginalFeedback = true"
          />
        </div>

        <div class="bg-white border-t border-gray-200 px-5 lg:px-7 py-4">
          <div v-if="reviewed && !isEditingFeedback" class="-mt-1 mb-2">
            <manual-review-meta
              :response-grade="responseGrade"
              :student="student"
              :assignment="assignment"
              :task="task"
              :task-needs-review="reviewsEnabledFor"
            />
          </div>

          <div v-if="isEditingFeedback" class="flex flex-col gap-2">
            <div class="flex items-center flex-wrap gap-3">
              <s-btn
                type="button"
                @click="toggleEditingFeedback"
                :disabled="editFeedbackForm.processing"
                class="flex-1"
                color="white"
                icon="close"
              >
                {{ t('form.cancel') }}
              </s-btn>
              <s-btn
                type="submit"
                class="flex-1"
                color="primary"
                :disabled="editFeedbackForm.processing"
                icon="content-save"
              >
                {{ editFeedbackForm.processing ? t('savingGrade') : t('saveGrade') }}
              </s-btn>
            </div>

            <div v-if="responseGrade && !responseGrade.isManuallyEntered">
              <s-divider>{{ t('or') }}</s-divider>
              <button
                class="flex-1 btn secondary md w-full mt-2"
                :disabled="regrading"
                @click.prevent="showRegradeConfirmationModal = true"
              >
                {{ regrading ? t('grading') : '✨' + t('autograde') + ' ✨' }}
              </button>
            </div>
          </div>

          <!-- FEEDBACK ACTIONS -->
          <div v-if="!isEditingFeedback" class="flex gap-3 items-center justify-end flex-wrap">
            <s-btn
              v-if="canBeRegraded"
              class="flex-1"
              color="secondary"
              :disabled="regrading"
              @click.prevent="showRegradeConfirmationModal = true"
            >
              {{ regrading ? t('autograding') : '✨' + t('autograde') + ' ✨' }}
            </s-btn>

            <!-- Manually Enter/Edit Grade -->
            <s-key-tooltip class="flex-1 flex" keys="e">
              <s-btn
                @click="toggleEditingFeedback"
                :color="responseGrade?.isEffective ? 'blue-light' : 'primary'"
                icon="pencil"
                class="flex-1"
              >
                <span v-if="notGraded">{{ t('enterGrade') }}</span>
                <span v-else>{{ t('editGrade') }}</span>
              </s-btn>
            </s-key-tooltip>

            <!-- Feedback Approval -->

            <s-key-tooltip v-if="canBeReviewed" class="flex-1 flex w-full" :keys="['A']">
              <s-btn class="flex w-full" icon="thumb-up" @click.prevent="approveGrade">
                {{ t('approveGrade') }}
              </s-btn>
            </s-key-tooltip>
          </div>
        </div>
      </form>
    </div>
    <!-- Sticky Footer -->
    <div class="px-5 lg:px-7 pt-4 pb-5 lg:pb-6 flex flex-col gap-3">
      <!-- Footer Header -->
      <div class="col-span-2 flex justify-between flex-wrap gap-2">
        <s-btn
          @click="
            () => {
              showAssignmentInfoModal = true;
            }
          "
          color="white"
          size="custom"
          class="px-4 py-1.5 -m-0.5 mr-0 font-bold text-sm leading-tight overflow-hidden"
        >
          <span v-if="courseLikeAssignment?.timelimitEnabled" class="mr-1 -ml-4 -my-1.5">
            <s-badge
              size="custom"
              class="px-3 py-1.5 rounded-r-none ml-[-0.5px] my-[-0.5px]"
              :color="timer?.hasFinished ? 'green' : timer?.isRunning ? 'blue' : 'gray'"
            >
              <s-icon name="timer" size="18" class="opacity-70" />
              <span class="sr-only">
                {{
                  timer?.hasFinished
                    ? t('timerComplete')
                    : timer?.isRunning
                      ? t('timerInProgress')
                      : t('timerNotStarted')
                }}
              </span>
            </s-badge>
          </span>
          <s-icon name="help-box-multiple" size="18" class="opacity-70" />
          {{ assignment.name }}
        </s-btn>
        <h2
          id="task-info"
          class="flex-1 flex items-center gap-1 flex-wrap items-center text-gray-600 font-bold text-sm leading-tight"
        >
          {{ t('task') }} {{ taskNumber }}
          <span class="opacity-50">&ndash;</span>
          <span class="italic font-medium">
            {{ t('taskWeight', {taskWeight}) }}
          </span>
        </h2>
        <div
          class="flex items-center flex-wrap gap-x-1 text-gray-600/85 font-medium text-sm leading-tight"
          :class="{
            'text-green-600': numAttemptedTasks === assignmentTasks.length,
            'text-gray-600': numAttemptedTasks < assignmentTasks.length,
          }"
        >
          <s-icon
            v-if="numAttemptedTasks === assignmentTasks.length"
            name="check-bold"
            size="16"
            class="opacity-70"
          />
          <i18n-t keypath="xOfYTasksAttempted">
            <template #number>
              <span class="text-gray-700 font-black">{{ numAttemptedTasks }}</span>
            </template>
            <template #total>
              <span class="text-gray-700 font-black">{{ assignmentTasks.length }}</span>
            </template>
          </i18n-t>
        </div>
      </div>

      <div class="w-full flex flex-col @2xl:grid @2xl:grid-cols-[auto_22rem] gap-4">
        <!-- Task Cards -->
        <div
          class="justify-start items-stretch gap-2"
          :class="
            assignmentTasks.length > 10
              ? 'grid grid-cols-[repeat(auto-fit,_minmax(2.75rem,_1fr))]'
              : 'grid grid-cols-[repeat(auto-fit,_minmax(2.75rem,_3.5rem))]'
          "
        >
          <template v-for="(task, index) in assignmentTasks" :key="task.id">
            <s-tooltip
              :text="
                determineTaskStateLabel(
                  attemptedTaskIds,
                  draftTaskIds,
                  studentAssignmentTaskGrades,
                  task.id
                )
              "
            >
              <s-link
                :href="
                  route('courses.assignments.marking.assignmentTasks.show', {
                    course: props.assignment.course.id,
                    assignment: props.assignment.id,
                    user: props.student.id,
                    taskNumber: index + 1,
                    _query: {sectionFilter: currentSectionId || ''},
                  })
                "
                preserve-state
                @click="showOriginalFeedback = false"
                :only="taskProps"
                :class="determineTaskNavItemClasses(task.id, props.task.id, assignmentTasks.length)"
              >
                <span
                  class="absolute top-0 left-0 right-0 w-full flex justify-between p-1 pointer-events-none"
                >
                  <s-icon
                    v-if="attachmentUploaded(task.id)"
                    name="paperclip"
                    size="14"
                    class="text-gray-400"
                  />
                </span>
                <span>{{ index + 1 }}</span>
                <span
                  class="absolute bottom-0 left-0 right-0 w-full flex justify-between p-1 pointer-events-none"
                >
                  <s-icon
                    v-if="taskIsReviewed(task.id)"
                    name="thumb-up"
                    size="16"
                    :class="task.id === props.task.id ? 'text-blue-50' : 'text-blue-400'"
                  ></s-icon>
                  <s-icon
                    v-else-if="reviewsEnabledFor(task.id)"
                    name="exclamation-thick"
                    size="16"
                    :class="task.id === props.task.id ? 'text-orange-300' : 'text-orange-400'"
                  ></s-icon>
                  <span class="flex-1"></span>
                  <s-icon
                    v-if="showTaskDraftIcon(task.id)"
                    name="pencil"
                    size="16"
                    class="text-gray-400"
                  />
                </span>
              </s-link>
            </s-tooltip>
          </template>
        </div>

        <div class="flex flex-col justify-end items-end">
          <div class="flex w-full max-w-sm">
            <s-key-tooltip class="flex-1 flex" keys="[">
              <button
                @click="prevTask"
                class="flex-1 btn white md block flex items-center gap-1.5 rounded-r-none"
                :disabled="props.task.id === props.assignmentTasks[0].id"
              >
                <s-icon name="arrow-left" size="16" class="opacity-70" />
                {{ t('previousTask') }}
              </button>
            </s-key-tooltip>
            <s-key-tooltip class="flex-1 flex" keys="]">
              <button
                @click="nextTask"
                class="flex-1 btn primary md block flex items-center gap-1.5 rounded-l-none"
                :disabled="
                  props.task.id === props.assignmentTasks[props.assignmentTasks.length - 1].id
                "
              >
                {{ t('nextTask') }}
                <s-icon name="arrow-right" size="16" class="opacity-70" />
              </button>
            </s-key-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <s-modal
    v-model:open="showRegradeConfirmationModal"
    v-model:processing="regrading"
    :title="t('confirmAutoGrade')"
    :message="
      (response?.isDraft ? t('responseSubmittedBehalf') : t('overwriteExistingFeedback')) +
      ' ' +
      t('wouldYouLikeToProceed')
    "
    :confirm="{
      label: regrading ? t('autograding') : t('autogradeResponse'),
    }"
    @confirm="regradeResponse"
    cancellable
  />

  <s-modal
    v-model:open="showDraftGradeConfirmationModal"
    :title="t('confirmDraftGrading')"
    :message="t('confirmConvertDraft')"
    @confirm="gradeDraft"
    cancellable
  />

  <s-modal v-model:open="showAssignmentInfoModal" :title="assignment.name">
    <s-modal-content
      :class="{
        '!pb-2': courseLikeAssignment?.timelimitEnabled,
      }"
    >
      <assignment-info-staff
        :assignment="assignment"
        :can-grant-timer-extensions="can.grantTimerExtensions"
        :course="assignment.course"
        :startDate="courseLikeAssignment?.startDate"
        :dueDate="courseLikeAssignment?.dueDate"
        :extendedStartDate="extension?.newStartDate"
        :extendedDueDate="extension?.newDueDate"
        :totalMarks="assignmentTotalMarks"
        :grade="assignmentGrade"
        :student="student"
        :timer="timer"
        :extended-due-date-id="extension?.id"
      >
        <template #actions>
          <s-link
            v-if="assignmentGrade?.id"
            class="link flex items-center gap-1"
            :href="route('assignment_grades.adjustments.create', {grade: assignmentGrade.id})"
          >
            <s-icon name="lock-open" size="16" />
            {{ t('overrideAssignmentGrade') }}
          </s-link>
        </template>
      </assignment-info-staff>
    </s-modal-content>
    <s-modal-content
      v-if="courseLikeAssignment?.timelimitEnabled"
      class="bg-gradient-to-b from-gray-100 via-gray-50 to-gray-50 border-t border-gray-150 text-gray-700 shadow-inset pt-4"
    >
      <assignment-timer-staff
        :timer="timer"
        :can-grant-timer-extensions="can.grantTimerExtensions"
        :course="assignment.course"
        :assignment="assignment"
        :timelimit-in-minutes="courseLikeAssignment?.timelimitInMinutes"
        :student="student"
      />
    </s-modal-content>
  </s-modal>
</template>

<script setup lang="ts">
import LegacyTask from '@/components/tasks/LegacyTask.vue';
import {useDraggable, useElementBounding, useStorage, useWindowSize} from '@vueuse/core';
import {computed, provide, ref, watch} from 'vue';
import {Grade} from '../../types/entities/base-grade';
import {Task, TaskFeedback, TaskResponse, TaskState} from '../../types/entities/tasks';
import {Assignment} from '../../types/entities/assignment';
import {route, RouteParams} from 'ziggy-js';
import {User} from '../../types/entities/user';
import SLink from '../../components/SLink.vue';
import {CourseLike} from '../../types/entities/course-like';
import {Head, router, useForm} from '@inertiajs/vue3';
import SInputField from '../../design-system/SInputField.vue';
import STextareaField from '../../design-system/STextareaField.vue';
import SResponseAttachmentList from '../../components/SResponseAttachmentList.vue';
import {useBreadcrumbs} from '../../composables/useBreadcrumbs';
import TaskStateTable from './Show/TaskStateTable.vue';
import StudentNavigation from './Show/StudentNavigation.vue';
import ManualReviewMeta from './Show/ManualReviewMeta.vue';
import BottomTab from './Show/BottomTab.vue';
import SBadgeGroup from '../../design-system/SBadgeGroup.vue';
import SBadge from '../../design-system/SBadge.vue';
import SDivider from '../../design-system/SDivider.vue';
import STooltip from '../../design-system/STooltip.vue';
import SKeyTooltip from '../../design-system/SKeyTooltip.vue';
import GradeReplacementDto = App.DTOs.GradeReplacementDto;
import StudentDropdownDto = App.DTOs.StudentDropdownDto;
import {useKeyboardShortcuts} from '../../composables/useKeyboardShortcut';
import SModal from '../../design-system/SModal.vue';
import SModalContent from '../../design-system/SModalContent.vue';
import {formatDistance} from '../../format/dates';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import STaskGradePill from '../../components/STaskGradePill.vue';
import {AssignmentExtension} from '../../types/entities/assignment-extension';
import {getResponseTimestamp, isResponseLate} from '../../util/response-timestamps';
import SBtn from '../../design-system/SBtn.vue';
import SLatex from '../../components/SLatex.vue';

// TODO: Move these into `components` directory.
import AssignmentInfoStaff from '../AssignmentTask/Show/AssignmentInfoStaff.vue';
import AssignmentTimerStaff from '../AssignmentTask/Show/AssignmentTimerStaff.vue';
import FeedbackByPart from '../AssignmentTask/Show/FeedbackByPart.vue';
import ResponseInfoAreaMarking from '../AssignmentTask/Show/ResponseInfoAreaMarking.vue';

// TODO: Move these into `composables` directory.
import {useTaskResponseStateColors} from '../AssignmentTask/Show/task-response-state-colors';
import SIcon from '../../design-system/SIcon.vue';
import {determineTaskState} from '../AssignmentTask/Show/task-response-state-utils';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import CourseLikeAssignmentDto = App.DTOs.CourseAssignments.CourseLikeAssignmentDto;
import TaskGradeDto = App.DTOs.TaskGradeDto;
import {useI18n} from 'vue-i18n';
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;
import Rubric = App.DTOs.Rubrics.Rubric;
import AssignmentTaskDto = App.DTOs.Tasks.AssignmentTaskDto;

type PageParams = {
  sectionFilter?: string;
};

const {t} = useI18n();

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const alwaysChanges = [
  'response',
  'responseGrade',
  'feedbackByPart',
  'partGrades',
  'studentAssignmentTaskGrades',
  'originalGrade',
  'originalGradeFeedback',
  'timer',
  'maxAttempts',
  'additionalAttempts',
];
const taskProps = [
  ...alwaysChanges,
  'taskNumber',
  'task',
  'taskResponses',
  'taskState',
  'taskRubric',
];
const responseProps = [...alwaysChanges];
const regradeProps = [...alwaysChanges, 'taskResponses'];

const props = defineProps<{
  // Permissions
  can: {
    grantTimerExtensions: boolean;
  };
  // Timer
  timer: UserAssignmentTimerDto | null;

  // Course
  courseLikeAssignment: CourseLikeAssignmentDto | null;
  courseSections: CourseLike[];

  // Assignment
  assignment: Assignment;
  assignmentTasks: AssignmentTaskDto[];
  taskNumber: number;
  assignmentGrade: AssignmentGradeDto;
  extension: AssignmentExtension | null;

  // Assignment Participants
  students: StudentDropdownDto[];

  // Student
  student: User;
  attemptedTaskIds: number[];
  draftTaskIds: number[];

  // Task
  task: Task;
  taskState: TaskState;
  taskRubric: Rubric | null;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];
  maxAttempts: number | null;
  additionalAttempts: number;

  // Response
  response: TaskResponse | null;
  responseGrade: TaskGradeDto | null;
  feedbackByPart: Record<string, TaskFeedback[]>;
  partGrades: TaskCriterionGradeDto[];
  attachmentTasksIds: number[];

  // Original Response Grade
  originalGrade: Grade | null;
  originalGradeFeedback: Record<string, TaskFeedback[]> | null;
}>();

useBreadcrumbs([
  {
    label: props.assignment.course.code,
    href: `/courses/${props.assignment.course.id}`,
    external: true,
  },
  {
    label: t('assignments'),
    href: route('courses.assignments.index', [props.assignment.course.id]),
  },
  {
    label: props.assignment.name,
    href: `/courses/${props.assignment.course.id}/assignments/${props.assignment.id}`,
    external: true,
  },
  {
    label: t('grades'),
    href: `/courses/${props.assignment.course.id}/assignments/${props.assignment.id}/grades`,
  },
]);

const selectedResponseId = computed(() => props.response?.id || props.taskResponses[0]?.id || null);

const selectedResponse = computed<PartialTaskResponse | null>({
  get: () =>
    props.taskResponses.find((response) => response.id === selectedResponseId.value) ?? null,
  set: (newResponse) => {
    if (!newResponse) {
      return;
    }

    router.visit(
      route('courses.assignments.marking.assignmentTasks.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        user: props.student.id,
        taskNumber: props.taskNumber,
        response: newResponse.id,
        _query: {sectionFilter: currentSectionId.value || undefined},
      }),
      {
        only: responseProps,
        preserveState: true,
      }
    );
  },
});

//@ts-ignore
const currentSectionId = ref((route().params as PageParams).sectionFilter);
const currentSectionIdString = ref(currentSectionId.value || '');
router.on('navigate', () => {
  //@ts-ignore
  currentSectionId.value = (route().params as PageParams).sectionFilter || '';
});

const assignmentTotalMarks = computed(() => {
  if (!props.assignmentTasks.length) {
    return 0;
  }
  return props.assignmentTasks.reduce((a, b) => a + b.pointValue, 0);
});

const numAttemptedTasks = computed(() => {
  return props.attemptedTaskIds.length;
});

const currentAssignmentTask = computed(() => props.assignmentTasks[props.taskNumber - 1]);

const taskWeight = computed(() => currentAssignmentTask.value?.pointValue || 0);

const attemptNumber = computed(() => {
  if (!props.response) {
    return;
  }

  return (
    props.taskResponses.length -
    props.taskResponses.findIndex((response) => response.id === props.response?.id)
  );
});

const numTaskAttempts = computed(() => {
  return props.taskResponses.filter((response) => !!response.submittedAt).length;
});

const finalAttempt = computed(() => {
  if (!props.maxAttempts) {
    return false;
  }

  return numTaskAttempts.value == props.maxAttempts + props.additionalAttempts - 1;
});

const noMoreAttempts = computed(() => {
  if (!props.maxAttempts) {
    return false;
  }

  return numTaskAttempts.value >= props.maxAttempts + props.additionalAttempts;
});

const responseIsLate = computed(() =>
  isResponseLate(props.courseLikeAssignment?.dueDate, props.response, props.extension)
);

const showTaskDraftIcon = (taskId: number) => {
  const taskHasDraft = props.draftTaskIds.includes(taskId);
  const taskHasActiveGrade = props.studentAssignmentTaskGrades.some(
    (grade) => grade.taskId === taskId && grade.isEffective
  );
  return taskHasDraft && !taskHasActiveGrade;
};

const attachmentUploaded = (taskId: number) => props.attachmentTasksIds.includes(taskId);

const determineTaskStateLabel = (
  attemptedTaskIds: number[],
  draftTaskIds: number[],
  studentAssignmentTaskGrades: Grade[],
  taskID: number
) => {
  const taskState = determineTaskState(
    {
      attemptedTaskIds,
      draftTaskIds,
      studentAssignmentTaskGrades,
    },
    taskID
  );

  if (taskState === 'incomplete') {
    return 'Incomplete';
  } else if (taskState === 'ungraded') {
    return 'Ungraded';
  } else if (taskState === 'incorrect') {
    return 'Incorrect';
  } else if (taskState === 'partial') {
    return 'Partial';
  } else if (taskState === 'correct') {
    return 'Correct';
  } else if (taskState === 'draft') {
    return 'Draft';
  } else {
    return 'Unknown';
  }
};

function prevTask() {
  const hasPreviousTask = props.taskNumber - 1 > 0;
  if (hasPreviousTask) {
    router.visit(
      route('courses.assignments.marking.assignmentTasks.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        user: props.student.id,
        taskNumber: props.taskNumber - 1,
        _query: {sectionFilter: currentSectionId.value || ''},
      }),
      {
        only: taskProps,
        preserveState: true,
      }
    );
  }
}

function nextTask() {
  const hasNextTask = props.assignmentTasks.length > props.taskNumber;
  if (hasNextTask) {
    router.visit(
      route('courses.assignments.marking.assignmentTasks.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        user: props.student.id,
        taskNumber: props.taskNumber + 1,
        _query: {sectionFilter: currentSectionId.value || ''},
      }),
      {
        only: taskProps,
        preserveState: true,
      }
    );
  }
}

const {
  determineFeedbackAreaBackgroundClass,
  determineFeedbackBorderBackgroundClass,
  determineTaskNavItemClasses,
} = useTaskResponseStateColors(props);

let currentResponseFeedback = computed(() => {
  let contentString = '';
  for (const part in props.feedbackByPart) {
    if (part !== 'default') {
      contentString += part + '\n';
    }
    for (const taskFeedback of props.feedbackByPart[part]) {
      contentString += taskFeedback.content + '\n';
    }
    contentString += '\n';
  }
  return contentString.trimEnd();
});

const currentGradePercent = computed(() => {
  if (!props.responseGrade) {
    return 0;
  }

  return Number(props.responseGrade.exactNumerator) / Number(props.responseGrade.exactDenominator);
});

/**
 * Editing Feedback Form
 */
const isEditingFeedback = ref(props.response && !props.responseGrade && !props.response.isDraft);

const toggleEditingFeedback = () => {
  isEditingFeedback.value = !isEditingFeedback.value;
};

const showOriginalFeedback = ref(false);

const editFeedbackForm = useForm<GradeReplacementDto>(() => ({
  numerator: Number((taskWeight.value * currentGradePercent.value ?? 0).toFixed(2)),
  // Because there are some tasks that have no weight, we need to fallback the denominator to 1 so we don't get a divide by 0 error on the backend.
  denominator: taskWeight.value || 1,
  reason: currentResponseFeedback.value || '',
}));

router.on('navigate', (event) => {
  isEditingFeedback.value = props.response && !props.responseGrade && !props.response.isDraft;
  editFeedbackForm.reset();
});

const showDraftGradeConfirmationModal = ref(false);

const gradeDraft = () => {
  submitGrade();
  showDraftGradeConfirmationModal.value = false;
};

const submitGrade = () => {
  if (props.response?.isDraft && !showDraftGradeConfirmationModal.value) {
    showDraftGradeConfirmationModal.value = true;
    return;
  }

  if (!props.response) {
    editFeedbackForm.post(
      route('courses.assignments.marking.tasks.grades.store', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        user: props.student.id,
        task: props.task.id,
      } as RouteParams<any>),
      {
        onSuccess: () => {
          isEditingFeedback.value = false;
        },
      }
    );
  } else {
    editFeedbackForm.post(
      route('courses.assignments.marking.tasks.responses.grades.store', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        user: props.student.id,
        task: props.task.id,
        response: props.response?.id,
      } as RouteParams<any>),
      {
        onSuccess: () => {
          isEditingFeedback.value = false;
        },
      }
    );
  }
};

/**
 * Regrading
 */
const showRegradeConfirmationModal = ref(false);
const regrading = ref(false);

async function regradeResponse() {
  regrading.value = true;

  if (!props.response) {
    regrading.value = false;
    return;
  }

  router.post(
    route('courses.assignments.marking.tasks.responses.regrade', {
      ...route().params,
      task: props.task.id,
      response: props.response.id,
    }),
    {},
    {
      onStart: () => (regrading.value = true),
      onFinish: () => {
        regrading.value = false;
        showRegradeConfirmationModal.value = false;
      },
      only: regradeProps,
      preserveState: true,
      preserveScroll: true,
    }
  );
}

/**
 * View Mode Logic
 */

type ViewMode = 'sticky' | 'scrolling';
const {height: windowHeight} = useWindowSize();
const viewMode = computed<ViewMode>(() => {
  return windowHeight.value < 700 ? 'scrolling' : 'sticky';
});

/**
 * Split Pane Resizing
 */

const defaultPanelPosition = 55;
const dividerPosition = useStorage('divider-position', defaultPanelPosition);
const containerRef = ref<HTMLElement | null>(null);
const dragHandle = ref<HTMLElement | null>(null);
const containerBounding = useElementBounding(containerRef);

const {x} = useDraggable(dragHandle, {
  axis: 'x',
  onMove: ({x}) => {
    const percentage =
      ((x + 8 - containerBounding.left.value) / containerBounding.width.value) * 100;
    if (percentage >= 25 && percentage <= 75) {
      dividerPosition.value = Number(percentage.toFixed(2));
    }
  },
});

const resetPaneSizes = () => {
  dividerPosition.value = defaultPanelPosition;
};

/**
 * Approvals
 */
const approveGrade = () => {
  if (!canBeReviewed.value) return;

  router.post(
    route('courses.assignments.marking.tasks.responses.grades.review', {
      course: props.assignment.course.id,
      assignment: props.assignment.id,
      user: props.student.id,
      task: props.task.id,
      response: props.response?.id,
      grade: props.responseGrade?.id,
    })
  );
};

/**
 * Keyboard Navigation
 */
const keyboardShortcuts = useKeyboardShortcuts();

keyboardShortcuts.register({key: 'e', label: 'Edit Grade', onPress: toggleEditingFeedback});

keyboardShortcuts.register({key: 'A', label: 'Approve Grade', onPress: approveGrade});

keyboardShortcuts.register({key: '[', label: 'Previous Task', onPress: prevTask});

keyboardShortcuts.register({key: ']', label: 'Next Task', onPress: nextTask});

/*
 * Random Variable Highlighting
 */
const showRandomVariables = ref(false);

provide('showRandomVariables', showRandomVariables);

/**
 *
 */
function taskIsReviewed(taskId: number) {
  return (
    reviewsEnabledFor(taskId) &&
    props.studentAssignmentTaskGrades.some(
      (grade) => grade.taskId === taskId && !!grade.reviewedAt && grade.isEffective
    )
  );
}

function reviewsEnabledFor(taskId: number): boolean {
  return !!props.assignmentTasks.find((task) => task.id === taskId)?.reviewRequired;
}

/**
 * Actions
 */
const canBeRegraded = computed(() => {
  return props.response && !isEditingFeedback.value && !props.responseGrade?.isManuallyEntered;
});

const canBeReviewed = computed(() => {
  return (
    submitted.value && reviewsEnabledFor(props.task.id) && !reviewed.value && isEffective.value
  );
});

/**
 * Status
 */
const reviewed = computed(() => {
  const grade = props.responseGrade;

  if (!grade) return false;

  return !!grade.reviewedById || grade.isManuallyEntered;
});

const graded = computed(() => !!props.responseGrade);
const notGraded = computed(() => !graded.value);

const submitted = computed(() => {
  return props.response && !props.response.isDraft;
});
const notSubmitted = computed(() => !submitted.value);

const isEffective = computed(() => props.responseGrade?.isEffective);

/**
 * Content
 *
 */
const showAssignmentInfoModal = ref(false);

const hasComparableFeedback = computed(() => props.feedbackByPart && props.originalGradeFeedback);

const showFeedbackTabs = computed(() => {
  return (
    props.responseGrade?.isManuallyEntered &&
    !isEditingFeedback.value &&
    submitted.value &&
    hasComparableFeedback.value
  );
});

// If at any point we don't have the data to show the original feedback, hide it
watch(
  hasComparableFeedback,
  (newValue) => {
    if (!newValue) {
      showOriginalFeedback.value = false;
    }
  },
  {flush: 'sync', immediate: true}
);

const selectedGrade = computed(() =>
  showOriginalFeedback.value && props.originalGrade ? props.originalGrade : props.responseGrade
);
const selectedFeedback = computed(() =>
  showOriginalFeedback.value && props.originalGradeFeedback
    ? props.originalGradeFeedback
    : props.feedbackByPart
);
</script>
<style scoped>
.feedback-scroll-shadow {
  background: /* Shadow Cover BOTTOM */
    linear-gradient(to top, white 1rem, transparent) bottom center,
    /* Shadow BOTTOM */ linear-gradient(to top, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) bottom
      center;
  background-repeat: no-repeat;
  background-size:
    100% 3rem,
    100% 1rem;
  background-attachment: local, scroll;
  position: relative;
  flex-shrink: 1;
  overflow-y: auto;
}
</style>
<i18n>
{
  "en": {
    "page": {
      "title": "Task #{taskNumber} for {firstName} {lastName} - {assignment} - {course}"
    },
    "saveGrade": "Save Grade",
    "task": "Task",
    "late": "late",
    "notGraded": "Not Graded",
    "autogradingFailed": "Autograding Failed", 
    "feedback": "Feedback",
    "draftState": "Response is in draft state.",
    "noResponse": "No response yet.",
    "manualFeedback": "Manual Feedback",
    "originalFeedback": "Original Feedback",
    "previousTask": "Previous Task",
    "nextTask": "Next Task",
    "confirmAutoGrade": "Confirm AutoGrade",
    "responseSubmittedBehalf": "The response will be submitted on behalf of the student.",
    "overwriteExistingFeedback": "This will overwrite the existing feedback and grade.",
    "wouldYouLikeToProceed": "Would you like to proceed?",
    "grading": "Grading...",
    "savingGrade": "Saving Grade...",
    "autograde": "Autograde",
    "autograding": "Autograding",
    "autogradeResponse": "Autograde Response",
    "confirmDraftGrading": "Confirm Draft Grading",
    "confirmConvertDraft": "This will convert the draft to a response for grading. Would you like to proceed?",
    "overrideAssignmentGrade": "Override Assignment Grade",
    "enterGrade": "Enter Grade",
    "editGrade": "Edit Grade",
    "approveGrade": "Approve Grade",
    "taskWeight": "Worth {taskWeight} points",
    "timerComplete": "Timer Complete",
    "timerInProgress": "Timer in Progress", 
    "timerNotStarted": "Timer Not Started",
    "responseXofY": "Response {number} of {total}",
    "xOfYTasksAttempted": "{number} of {total} Tasks Attempted",
    "xPoints": "{count} point | {count} points",
    "resizeHandle": "Resize Task and Feedback Panes",
    "or": "or",
    "noMoreAttempts": "No More Attempts",
    "finalAttempt": "Final Attempt",
    "attemptsCount": "{count} attempts made",
    "outOfMaxAttempts": " out of a maximum of {max}",
    "attemptsBreakdown": " ({base} base + {additional} additional)",
    "attemptsDisplay": "{current} of {max} Attempts Made",
    "grantAdditionalAttempt": "Grant Additional Attempt",
    "viewHistory": "View History",
    "additionalAttemptsGranted": "{count} additional attempts granted",
    "clickToViewDetails": "Click to view details"
  },
  "fr": {
    "page": {
      "title": "Tâche #{taskNumber} de {firstName} {lastName} - {assignment} - {assignment}"
    },
    "or": "ou",
    "saveGrade": "Sauvegarder la note",
    "task": "Tâche",
    "late": "en retard",
    "notGraded": "Non noté",
    "autogradingFailed": "Échec de l'évaluation automatique",
    "feedback": "Commentaire",
    "draftState": "La réponse est en état de brouillon.",
    "noResponse": "Pas encore de réponse.",
    "manualFeedback": "Commentaires manuels",
    "originalFeedback": "Commentaires originaux",
    "previousTask": "Tâche précédente",
    "nextTask": "Tâche suivante",
    "confirmAutoGrade": "Confirmer l'évaluation automatique",
    "responseSubmittedBehalf": "La réponse sera soumise au nom de l'élève.",
    "overwriteExistingFeedback": "Cela écrasera les commentaires et la note existants.",
    "wouldYouLikeToProceed": "Voulez-vous continuer ?",
    "grading": "Évaluation...",
    "savingGrade": "Sauvegarde de la note...",
    "autograde": "Évaluation automatique",
    "autograding": "Évaluation automatique en cours",
    "autogradeResponse": "Évaluer la réponse automatiquement",
    "confirmDraftGrading": "Confirmer l'évaluation du brouillon",
    "confirmConvertDraft": "Cela convertira le brouillon en réponse pour évaluation. Voulez-vous continuer ?",
    "overrideAssignmentGrade": "Remplacer la note du devoir",
    "enterGrade": "Entrer la note",
    "editGrade": "Modifier la note",
    "approveGrade": "Approuver la note",
    "taskWeight": "Vaut {taskWeight} points",
    "timerComplete": "Minuteur terminé",
    "timerInProgress": "Minuteur en cours",
    "timerNotStarted": "Minuteur non démarré",
    "responseXofY": "Réponse {number} sur {total}",
    "xOfYTasksAttempted": "{number} sur {total} tâches tentées",
    "xPoints": "{count} point | {count} points",
    "resizeHandle": "Redimensionner les volets des tâches et des commentaires",
    "noMoreAttempts": "Plus de tentatives possibles",
    "finalAttempt": "Dernière tentative",
    "attemptsCount": "{count} tentatives effectuées",
    "outOfMaxAttempts": " sur un maximum de {max}",
    "attemptsBreakdown": " ({base} de base + {additional} supplémentaires)",
    "attemptsDisplay": "{current}/{max} Tentatives effectuées",
    "grantAdditionalAttempt": "Accorder une tentative supplémentaire",
    "viewHistory": "Voir l'historique",
    "additionalAttemptsGranted": "{count} tentatives supplémentaires accordées",
    "clickToViewDetails": "Cliquez pour voir les détails"
  }
}
</i18n>
