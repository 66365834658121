import {Schema} from 'prosemirror-model';
import {text} from '../../prosemirror-editor/schema/node_specs/text';
import {taskTextBlock} from './node_specs/task-text-block';
import {taskSingleCalculation} from './node_specs/task-single-calculation';

let dataDriveTaskDoc = {
  content: 'block*',
  attrs: {
    title: {default: ''},
    status: {default: 'draft'},
    youtubeId: {default: null},
    textbookLink: {default: null},
    textbookLinkText: {default: null},
    textbookLinkIcon: {default: null},
    draftable: {default: true},
    type: {default: 'DataDrivenTask'},
    gradingAlgorithmUuid: {default: null},
    institutionId: {default: null},
  },
};

let doc = {
  content: 'block*',
};

const nodes = {
  /// NodeSpec The top level document node.
  doc: dataDriveTaskDoc,
  taskTextBlock,
  taskSingleCalculation,
  text,
};

export const marks = {};

export const taskSchema = new Schema({
  nodes,
  marks,
});
