<script setup lang="ts">
import SLatex from '../../../components/SLatex.vue';
import {TaskFeedback} from '../../../types/entities/tasks';
import {computed} from 'vue';

const props = defineProps<{
  feedbackByPart: Record<string, TaskFeedback[]> | null;
  useHtmlNewlines?: boolean;
}>();

const sortedFeedbacks = computed(() => {
  const feedback = props.feedbackByPart ?? {};
  const parts = Object.keys(feedback);

  parts.sort((a, b) => {
    if (a == 'default') {
      return -1;
    } else if (b == 'default') {
      return 1;
    }
    return a.localeCompare(b);
  });

  return parts.map((part) => ({
    part,
    items: feedback[part],
  }));
});

function replaceNewlines(feedback: string) {
  if (props.useHtmlNewlines) {
    return feedback.replace(/\r\n|\r|\n/g, '<br>');
  }
  return feedback;
}
</script>

<template>
  <div v-for="{items, part} in sortedFeedbacks">
    <p v-if="part != 'default'">
      <s-latex :content="part" />
    </p>
    <!-- TODO: Should this be an ordered list since the feedbacks have a sorted order? -->
    <ul>
      <li v-for="feedback in items">
        <s-latex :content="replaceNewlines(feedback.content)" :use-html="useHtmlNewlines" />
      </li>
    </ul>
  </div>
</template>
