<template>
  <div class="w-full h-full grid grid-rows-1 grid-cols-1">
    <div
      v-if="inertiaComponentState === 'loading' || legacyFrameLoading"
      id="legacy-loader"
      class="px-6 py-12 flex items-center justify-center row-start-1 row-end-2 col-start-1 col-end-2"
    >
      <span class="spinner"></span>
    </div>
    <!--
      The :key property is quite important here, as it ensures the component is fully reloaded when switching between tasks.
      We don't want any component state pollution when a user switches tasks.
    -->
    <component
      :key="task.id"
      :is="inertiaComponent"
      :form="form"
      :seed
      :task="task"
      :taskState="taskState"
      :rubric="rubric"
      :submitting="isSubmitting"
      :attachments="storedResponseAttachments"
      :feedback-by-part="storedResponseFeedbackByParts"
      :part-grades="storedResponsePartGrades"
      @add:files="emit('update:taskAttachments', $event)"
      @submit="$emit('submit-response', {response: form.input})"
      :can
    />
    <!-- eslint-disable      -->
    <iframe
      title="Assignment Task"
      src="/inertia/task"
      @load="legacyFrame = $event.target as HTMLIFrameElement"
      width="100%"
      :height="inertiaComponentState === 'loading' ? '0' : height"
      class="row-start-1 row-end-2 col-start-1 col-end-2"
      :class="{
        /**
         * We just hide the iframe so if we switch to the legacy component
         * we don't get as bad of a load time.
         */
        hidden: inertiaComponentState !== 'missing',
      }"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import {computed, defineAsyncComponent, PropType, ref, toRef, toRefs, watch} from 'vue';
import {useLegacyTaskComponent} from '../../composables/tasks/useLegacyTaskComponent';
import {Task, TaskFeedback, TaskResponse, TaskState} from '../../types/entities/tasks';
import {useForm, usePage} from '@inertiajs/vue3';
import {normalizeDataDrivenTaskInput} from './task-parts/normalizeDataDrivenTaskInput';
import TaskResponseAttachmentDto = App.DTOs.Tasks.TaskResponseAttachmentDto;
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;
import Rubric = App.DTOs.Rubrics.Rubric;

const props = defineProps({
  task: {
    type: Object as PropType<Task>,
    required: true,
  },
  taskState: {
    type: Object as PropType<TaskState>,
    default: null,
  },
  rubric: {
    type: Object as PropType<Rubric | undefined | null>,
    default: null,
  },
  seed: {
    type: Number as PropType<number>,
  },
  workingResponseCopy: {
    type: Object as PropType<TaskResponse | null>,
    default: null,
  },
  storedResponseId: {
    type: Number as PropType<number | null>,
  },
  storedResponseContent: {
    type: Object as PropType<any>,
    default: null,
  },
  storedResponseIsDraft: {
    type: Boolean as PropType<boolean>,
  },
  storedResponseAttachments: {
    type: Array as PropType<TaskResponseAttachmentDto[]>,
    default: () => [],
  },
  storedResponseFeedbackByParts: {
    type: Object as PropType<Record<string, TaskFeedback[]>>,
    default: () => ({}),
  },
  storedResponsePartGrades: {
    type: Array as PropType<TaskCriterionGradeDto[]>,
    default: () => [],
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  showRandomVariables: {
    type: Boolean,
    default: false,
  },
  can: {
    type: Object as PropType<Record<string, boolean>>,
    default: () => ({}),
  },
});

const page = usePage();

const enableInertiaDataDrivenTasks = computed(() => {
  // @ts-ignore
  return page.props?.features?.inertiaDataDrivenTasks;
});

const emit = defineEmits([
  'ready',
  'submit-response',
  'update:responseData',
  'update:taskAttachments',
]);

/**
 * Inertia Form for tasks that are built with Inertia components.
 */
const form = useForm(() => {
  let input = props.storedResponseContent;

  // Note: Sometimes Laravel will return an empty array instead of an object
  //       if it doesn't have any properties. This is a weirdness with how
  //       PHP arrays are serialized to JSON.
  // TODO: We should be able to force Laravel to return an object instead of an array.
  if (!input || Array.isArray(input) || Array.isArray(input.data)) {
    // DynamicQuestiosn and DataDrivenTasks expect their content to
    // nested inside a `data` attribute in the form.
    input = {data: {}};
  }

  if (enableInertiaDataDrivenTasks.value && props.task.taskType === 'DataDrivenTask') {
    input = normalizeDataDrivenTaskInput(props.task, input);
  }

  return {
    taskId: props.task.id,
    responseId: props.storedResponseId,
    input,
  };
});

/**
 * Reset Inertia Form
 * - Task Changes
 * - Response Changes
 */
watch(
  [toRef(props, 'task'), toRef(props, 'storedResponseId'), toRef(props, 'storedResponseIsDraft')],
  ([newTask, newResponseId, newResponseIsDraft], [oldTask, oldResponseId, oldIsDraft]) => {
    if (newTask?.id !== oldTask?.id) {
      form.reset();
      return;
    }

    const responseHasChanged = newResponseId !== oldResponseId;
    const switchedToDraft = responseHasChanged && newResponseIsDraft;
    const createdNewDraft = switchedToDraft && form.isDirty;

    if (createdNewDraft) {
      return;
    }

    form.reset();
  }
);

/**
 * Emit changes to the Inertia Form IFF we're editing an Inertia Component.
 */
watch(
  () => form.data(),
  () => {
    if (inertiaComponentState.value !== 'loaded') {
      return;
    }

    if (!form.isDirty) {
      return;
    }

    emit('update:responseData', form.input);
  },
  {deep: true}
);

/**
 * Name of the Inertia Component to load.
 */
const componentName = computed(() => {
  return props.task.content?.component || props.task?.taskType;
});

const inertiaComponentState = ref<'loading' | 'loaded' | 'missing'>('loading');

const inertiaComponent = computed(() => {
  // NOTE: We need this to force Vue to re-evaluate the componentPath.
  let componentPath = `./${componentName.value}.vue`;

  return defineAsyncComponent({
    loader: async () => {
      inertiaComponentState.value = 'loading';

      if (!enableInertiaDataDrivenTasks.value && componentName.value === 'DataDrivenTask') {
        throw new Error('DataDrivenTask is not enabled');
      }

      // NOTE: The inlined import is necessary for Vite to correctly build the js bundle.
      const component = await import(`./task-types/${componentName.value}.vue`);

      inertiaComponentState.value = 'loaded';

      return component;
    },
    onError() {
      inertiaComponentState.value = 'missing';
    },
  });
});

/**
 * Legacy Frontend iFrame
 */
const legacyFrame = ref<HTMLIFrameElement | null>(null);
const {height} = useLegacyTaskComponent({
  frame: legacyFrame,
  ...toRefs(props),
  emit(...args: any[]) {
    /*
     * The Legacy Vue components are _always_ rendered for performance reasons.
     *
     * This guard clause prevents the Legacy Vue components from updating the form
     * state when we have loaded an Inertia component.
     *
     * This is some _very_ defensive programming. It probably isn't strictly necessary,
     * but makes us feel warm and safe.
     */
    if (inertiaComponentState.value !== 'missing') {
      return;
    }
    // @ts-ignore
    emit(...args);
  },
});

const legacyFrameLoading = computed(() => {
  return inertiaComponentState.value === 'missing' && !height.value;
});
</script>

<style>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  transform-origin: 50% 50%;
  animation: spin 1.25s infinite linear;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background: conic-gradient(from 180deg at 50% 50%, rgba(42, 62, 142, 0) 0deg, #2da3dc 360deg);
  mix-blend-mode: darken;
}

.spinner:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.75rem;
  height: 1.75rem;
  transform: translate3d(-50%, -50%, 0);
  background: white;
  border-radius: 100%;
}
</style>
