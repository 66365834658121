<template>
  <edit-assignment-header :course="course" :assignment="assignment" :can="can" />

  <form id="settings-form" @submit.prevent="submitForm" class="flex-1 flex flex-col">
    <s-page-block size="lg" class="flex-1">
      <div class="flex items-start flex-wrap gap-4 mb-4">
        <div class="min-w-0 flex flex-col items-stretch gap-1 pb-1">
          <s-label>{{ t('taskReviewRequired') }}</s-label>
          <s-button-toggle
            id="reviewRequired"
            name="reviewRequired"
            :options="[
              {value: false, label: t('no')},
              {value: true, label: t('yes')},
            ]"
            v-model="form.reviewRequired"
          />
        </div>
        <s-multi-select-field
          v-if="form.reviewRequired"
          v-model="form.tasksToReview"
          id="assignedSections"
          :label="t('tasksToReview')"
          :tooltip="t('tasksShouldBeReviewed')"
          :emptyLabel="t('pleaseSelect')"
          :options="assignmentTasks"
          id-key="id"
          value-key="id"
        >
          <template #selected="{selected}: {selected: LoadedTaskAssignmentDto[]}">
            {{ t('tasks') }}{{ t(':') }}
            <span class="comma-separated">
              <span v-for="option in selected.sort((a, b) => a.orderingIndex - b.orderingIndex)">
                {{ option.orderingIndex }}
              </span>
            </span>
          </template>

          <template #option="{option}: {option: LoadedTaskAssignmentDto}">
            <div class="flex justify-between w-full @container/task-select">
              <span class="flex-1 truncate">
                {{ option.orderingIndex }}{{ t(':') }}
                <span class="truncate">
                  {{ option.taskPool.tasks.map((task) => task.title).join(', ') }}
                </span>
              </span>
              <span>
                <s-badge v-if="option.taskPool.tasks.some((x) => x.isAiGraded)" size="sm">
                  <s-icon name="creation-outline" size="12" />
                  {{ t('ai') }}
                  <span class=""></span>
                </s-badge>
              </span>
            </div>
          </template>
        </s-multi-select-field>
      </div>

      <!-- Assigned Sections -->
      <div class="mb-4">
        <s-multi-select-field
          :modelValue="selectedCourseLikes"
          @update:modelValue="processSelectedCourseLikesChange"
          id="assignedSections"
          :label="t('assignedTo')"
          :emptyLabel="t('pleaseSelect')"
          :disableOption="(option: CourseLikeMultiselectOption) => !option.selectable"
          :options="courseLikeMultiSelectOptions"
          label-key="name"
        >
          <template #selected="{selected: courseLikes}: {selected: CourseLikeMultiselectOption[]}">
            <span v-if="courseLikes.length === 1 && courseLikes[0].id === course.id">
              {{ t('entireCourse') }}
            </span>
            <span v-else>
              {{ t('sections') }}
              <span class="comma-separated">
                <span v-for="courseLikeName in courseLikes">
                  {{ courseLikeName.name }}
                </span>
              </span>
            </span>
          </template>

          <template #option="{option}: {option: CourseLikeMultiselectOption}">
            <span v-if="option.id === course.id">{{ t('entireCourse') }}</span>
            <span v-else>{{ option.name }}</span>
          </template>
        </s-multi-select-field>
        <s-btn
          v-if="userCanAssignToEntireCourse && currentlyUnassignedOrAssignedToEntireCourse"
          @click="assignToAllCourseSections"
          color="blue-light"
          class="gap-0.5 text-sm leading-tight font-bold mt-2.5"
          size="sm"
          type="button"
        >
          <s-icon name="pencil" size="16" />
          {{ t('assignIndividualSectionDates') }}
        </s-btn>
      </div>

      <!-- Section Filter & Settings Table -->
      <div class="flex flex-col mb-2">
        <label class="font-bold text-gray-700 leading-tight mb-1.5">
          {{ t('sectionSettings') }}
        </label>
        <!-- Filter Assigned Sections -->
        <div class="relative mb-4">
          <s-input-field
            id="filterText"
            :disabled="noSectionsSelected"
            :placeholder="t('filterAssignmentSettings')"
            v-model="courseLikeNameFilterText"
          />
          <s-icon
            name="magnify"
            class="pointer-events-none absolute top-1/2 -translate-y-1/2 right-4 text-blue-500 transition-all ease-out duration-150"
            :class="courseLikeNameFilterText !== '' ? 'opacity-0' : 'opacity-70'"
            size="20"
          />
          <button
            type="button"
            class="absolute top-1/2 -translate-y-1/2 right-2 text-gray-600/50 hover:text-blue-500 transition-all ease-out duration-150 p-2 flex items-center justify-center"
            :class="courseLikeNameFilterText === '' ? 'opacity-0' : 'opacity-70'"
            @click="courseLikeNameFilterText = ''"
          >
            <s-icon name="close" size="20" />
          </button>
        </div>

        <div
          v-if="noSectionsSelected"
          class="bg-gray-500/5 rounded-lg shadow-inset border border-gray-250 w-full bg-gray-1000 flex justify-center items-center py-5"
        >
          <p class="text-gray-400 italic">{{ t('noSectionsSelected') }}</p>
        </div>
        <div
          v-if="allRowsAreHiddenByFilter && !noSectionsSelected"
          class="bg-gray-500/5 rounded-lg shadow-inset border border-gray-250 w-full bg-gray-1000 flex justify-center items-center py-5"
        >
          <p class="text-gray-400 italic">
            {{ t('noSectionsMatchFilter') }}
          </p>
        </div>
        <div v-if="!noSectionsSelected && !allRowsAreHiddenByFilter" class="section-table-wrapper">
          <table class="section-table">
            <thead class="">
              <tr>
                <th class="">{{ t('section') }}</th>
                <th class="">
                  {{ t('startDate') }}
                  <div class="">
                    <button
                      type="button"
                      class="section-edit-all-button"
                      @click="
                        openDateModal({
                          title: t('startDateAllSections'),
                          tagline: props.assignment.name,
                          mode: 'fixed',
                          label: t('New Start Date'),
                          value: firstDisplayedStartDateUserCanAssignTo,
                          recordsToModifyOnApply: allDisplayedCourseLikeAssignmentsUserCanAssignTo,
                          callbackToExecuteOnApply: processStartDateChanges,
                        })
                      "
                    >
                      <s-icon name="pencil" size="14" class="-m-0.5" />
                      {{ t('editAll') }}
                    </button>
                  </div>
                </th>
                <th>
                  {{ t('duration') }}
                  <div class="">
                    <button
                      type="button"
                      class="section-edit-all-button"
                      @click="
                        openDateModal({
                          title: t('durationAllSections'),
                          tagline: props.assignment.name,
                          mode: 'relative',
                          label: t('dueDate'),
                          value: firstDisplayedDueDateUserCanAssignTo,
                          relativeToValue: firstDisplayedStartDateUserCanAssignTo,
                          recordsToModifyOnApply: allDisplayedCourseLikeAssignmentsUserCanAssignTo,
                          callbackToExecuteOnApply: processDueDateChanges,
                        })
                      "
                    >
                      <s-icon name="pencil" size="14" class="-m-0.5" />
                      {{ t('editAll') }}
                    </button>
                  </div>
                </th>
                <th class="">
                  {{ t('dueDate') }}
                  <div class="">
                    <button
                      type="button"
                      class="section-edit-all-button"
                      @click="
                        openDateModal({
                          title: t('dueDateAllSections'),
                          tagline: props.assignment.name,
                          mode: 'both',
                          label: t('dueDate'),
                          value: firstDisplayedDueDateUserCanAssignTo,
                          relativeToValue: firstDisplayedStartDateUserCanAssignTo,
                          recordsToModifyOnApply: allDisplayedCourseLikeAssignmentsUserCanAssignTo,
                          callbackToExecuteOnApply: processDueDateChanges,
                        })
                      "
                    >
                      <s-icon name="pencil" size="14" class="-m-0.5" />
                      {{ t('editAll') }}
                    </button>
                  </div>
                </th>
                <th class="th-group left">
                  {{ t('policies') }}
                  <div>
                    <button
                      type="button"
                      class="section-edit-all-button"
                      @click="openPolicyModal(allDisplayedCourseLikeAssignmentsUserCanAssignTo)"
                    >
                      <s-icon name="pencil" size="14" class="-m-0.5" />
                      {{ t('editAll') }}
                    </button>
                  </div>
                </th>
                <th class="th-group small">
                  {{ t('quizMode') }}
                </th>
                <th class="th-group small">
                  {{ t('gradeRelease') }}
                </th>
                <th v-if="features.assignmentTimelimits" class="th-group small">
                  {{ t('timeLimit') }}
                </th>
                <th class="th-group small">
                  {{ t('lateResponses') }}
                </th>
                <th class="th-group small">
                  {{ t('latePenalty') }}
                </th>
                <th class="th-group small top-right">
                  {{ t('cutoffDate') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="courseLikeAssignment in filteredCourseLikeAssignments"
                :key="courseLikeAssignment.courseLikeId"
              >
                <tr
                  :class="{
                    'grayscale	opacity-80 pointer-events-none': !courseLikeAssignment.userCanAssign,
                  }"
                >
                  <td class="min-w-[10rem]">
                    <div class="flex items-center justify-between gap-2">
                      <span class="leading-tight min-w-[8rem]">
                        {{ courseLikeAssignment.courseLikeName }}
                      </span>
                      <s-tooltip>
                        <template #tooltip>
                          <span v-if="isVisible(courseLikeAssignment)">
                            {{ t('tooltips.visible') }}
                          </span>
                          <span v-else>
                            {{ t('tooltips.notVisible') }}
                          </span>
                        </template>

                        <s-badge
                          :color="isVisible(courseLikeAssignment) ? 'green' : 'blue-light'"
                          size="custom"
                          class="px-1.5 py-0.5 text-xs flex items-center gap-1 leading-none"
                        >
                          <s-icon
                            :name="isVisible(courseLikeAssignment) ? 'eye' : 'eye-off'"
                            size="14"
                            class="text-grey-500 opacity-70"
                          />
                          <span v-if="isVisible(courseLikeAssignment)">
                            {{ t('badges.visible') }}
                          </span>
                          <span v-else>
                            {{ t('badges.notVisible') }}
                          </span>
                        </s-badge>
                      </s-tooltip>
                    </div>
                  </td>
                  <td class="w-0">
                    <button
                      v-if="courseLikeAssignment.userCanAssign"
                      type="button"
                      class="section-time-button"
                      @click="
                        openDateModal({
                          title:
                            t('startDate') + t(':') + ' ' + courseLikeAssignment.courseLikeName,
                          tagline: props.assignment.name,
                          mode: 'fixed',
                          label: t('New Start Date'),
                          value: courseLikeAssignment.startDate,
                          recordsToModifyOnApply: [courseLikeAssignment],
                          callbackToExecuteOnApply: processStartDateChanges,
                        })
                      "
                    >
                      <table-date :dateTime="courseLikeAssignment.startDate" />
                    </button>
                    <span v-else class="section-time">
                      <table-date :dateTime="courseLikeAssignment.startDate" />
                    </span>
                  </td>
                  <td class="relative w-0">
                    <span
                      class="absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-300 text-base font-bold"
                    >
                      &ndash;
                    </span>
                    <button
                      type="button"
                      class="section-time-button"
                      @click="
                        openDateModal({
                          title: t('durationDash') + courseLikeAssignment.courseLikeName,
                          tagline: props.assignment.name,
                          mode: 'relative',
                          label: t('dueDate'),
                          value: courseLikeAssignment.dueDate,
                          relativeToValue: courseLikeAssignment.startDate,
                          recordsToModifyOnApply: [courseLikeAssignment],
                          callbackToExecuteOnApply: processDueDateChanges,
                        })
                      "
                    >
                      <span class="text-base leading-tight">
                        {{
                          getDuration(courseLikeAssignment.startDate, courseLikeAssignment.dueDate)
                        }}
                      </span>
                    </button>
                    <span
                      class="absolute right-0 top-1/2 translate-x-1/2 -translate-y-1/2 text-gray-300 text-base font-bold"
                    >
                      &ndash;
                    </span>
                  </td>
                  <td class="">
                    <div class="flex items-center gap-1">
                      <s-tooltip
                        v-if="hasError('dueDate', courseLikeAssignment.courseLikeId)"
                        data-error
                      >
                        <template #tooltip>
                          {{ getErrorMessage('dueDate', courseLikeAssignment.courseLikeId) }}
                        </template>
                        <s-icon name="warning" size="18" class="text-red-500" />
                      </s-tooltip>
                      <button
                        v-if="courseLikeAssignment.userCanAssign"
                        type="button"
                        class="section-time-button"
                        :class="{
                          '!text-red-600 hover:!text-blue-400': hasError(
                            'dueDate',
                            courseLikeAssignment.courseLikeId
                          ),
                        }"
                        @click="
                          openDateModal({
                            mode: 'both',
                            title: t('dueDate') + courseLikeAssignment.courseLikeName,
                            tagline: props.assignment.name,
                            label: t('new.dueDate'),
                            value: courseLikeAssignment.dueDate,
                            relativeToValue: courseLikeAssignment.startDate,
                            recordsToModifyOnApply: [courseLikeAssignment],
                            callbackToExecuteOnApply: processDueDateChanges,
                          })
                        "
                      >
                        <table-date :dateTime="courseLikeAssignment.dueDate" />
                      </button>
                      <span v-else class="section-time">
                        <table-date :dateTime="courseLikeAssignment.dueDate" />
                      </span>
                    </div>
                  </td>
                  <td class="w-0 td-group left">
                    <s-btn
                      v-if="courseLikeAssignment.userCanAssign"
                      type="button"
                      color="blue-light"
                      size="custom"
                      class="px-2.5 py-1 text-xs uppercase font-bold gap-1"
                      @click="openPolicyModal([courseLikeAssignment])"
                    >
                      <s-icon name="pencil" size="16" />
                      {{ t('edit') }}
                    </s-btn>
                  </td>
                  <td class="w-0 td-group">
                    <s-tooltip
                      v-if="courseLikeAssignment.quizMode"
                      :text="t('tooltips.quizModeEnabled')"
                    >
                      <s-icon name="check-circle" size="20" class="text-blue-500" />
                    </s-tooltip>
                    <s-tooltip v-else :text="t('tooltips.quizModeDisabled')">
                      <s-icon name="close" size="16" class="text-gray-700/40" />
                    </s-tooltip>
                  </td>

                  <td class="w-0 td-group">
                    <div class="flex items-center gap-1">
                      <s-tooltip
                        v-if="hasError('quizModeReleaseDate', courseLikeAssignment.courseLikeId)"
                        :text="
                          getErrorMessage('quizModeReleaseDate', courseLikeAssignment.courseLikeId)
                        "
                        data-error
                      >
                        <s-icon name="warning" size="18" class="text-red-500" />
                      </s-tooltip>
                      <span
                        class="section-time"
                        :class="
                          hasError('quizModeReleaseDate', courseLikeAssignment.courseLikeId)
                            ? 'error'
                            : ''
                        "
                      >
                        <table-date :dateTime="courseLikeAssignment.quizModeReleaseDate" />
                      </span>
                    </div>
                  </td>
                  <td v-if="features.assignmentTimelimits" class="w-0 td-group">
                    <s-tooltip
                      v-if="courseLikeAssignment.timelimitEnabled"
                      :text="`${courseLikeAssignment.timelimitInMinutes} minutes`"
                    >
                      <s-icon name="check-circle" size="20" class="text-blue-500" />
                    </s-tooltip>
                    <s-tooltip v-else :text="t('noTimelimit')">
                      <s-icon name="close" size="16" class="text-gray-700/40" />
                    </s-tooltip>
                  </td>
                  <td class="w-0 td-group">
                    <s-tooltip
                      v-if="courseLikeAssignment.preventPastDueSubmission"
                      :text="t('lateSubmissionsNotAllowed')"
                    >
                      <s-icon name="close" size="16" class="text-gray-700/40" />
                    </s-tooltip>
                    <s-tooltip v-else :text="t('lateSubmissionsAllowed')">
                      <s-icon name="check-circle" size="20" class="text-blue-500" />
                    </s-tooltip>
                  </td>
                  <td class="w-0 td-group">
                    <s-tooltip
                      v-if="
                        [
                          'lateResponsePenaltyData.penaltyValue',
                          'lateResponsePenaltyData.frequencyValue',
                        ].some((field) => hasError(field, courseLikeAssignment.courseLikeId))
                      "
                      data-error
                    >
                      <template #tooltip>
                        <p
                          v-for="field in [
                            'lateResponsePenaltyData.penaltyValue',
                            'lateResponsePenaltyData.frequencyValue',
                          ]"
                          :key="field"
                        >
                          {{ getErrorMessage(field, courseLikeAssignment.courseLikeId) }}
                        </p>
                      </template>
                      <s-icon name="warning" size="18" class="text-red-500" />
                    </s-tooltip>
                    <s-tooltip v-else-if="courseLikeAssignment.lateResponsePenaltyData">
                      <template #tooltip>
                        <p class="font-normal">
                          <strong class="font-bold capitalize">
                            {{ courseLikeAssignment.lateResponsePenaltyData.penaltyType }}
                          </strong>
                          <i18n-t keypath="penaltyOf" tag="p">
                            <template v-slot:penaltyValue>
                              <strong>
                                {{ courseLikeAssignment.lateResponsePenaltyData.penaltyValue }}
                              </strong>
                            </template>
                            <template v-slot:frequencyValue>
                              <strong>
                                {{ courseLikeAssignment.lateResponsePenaltyData.frequencyValue }}
                              </strong>
                            </template>
                            <template v-slot:frequencyUnit>
                              <strong>
                                {{
                                  t(
                                    'time.' +
                                      courseLikeAssignment.lateResponsePenaltyData.frequencyUnit,
                                    courseLikeAssignment.lateResponsePenaltyData.frequencyValue
                                  )
                                }}
                              </strong>
                            </template>
                          </i18n-t>
                        </p>
                      </template>
                      <s-icon name="calendar-alert" size="22" class="text-blue-500" />
                    </s-tooltip>
                    <s-tooltip v-else :text="t('noLateResponsePenaltyApplied')">
                      <s-icon name="close" size="16" class="text-gray-700/40" />
                    </s-tooltip>
                  </td>
                  <td class="w-0 td-group right">
                    <div class="flex items-center gap-1">
                      <s-tooltip
                        v-if="
                          hasError(
                            'lateResponsePenaltyData.submissionCutoff',
                            courseLikeAssignment.courseLikeId
                          )
                        "
                        :text="
                          getErrorMessage(
                            'lateResponsePenaltyData.submissionCutoff',
                            courseLikeAssignment.courseLikeId
                          )
                        "
                        data-error
                      >
                        <s-icon name="warning" size="18" class="text-red-500" />
                      </s-tooltip>
                      <span
                        class="section-time"
                        :class="
                          hasError(
                            'lateResponsePenaltyData.submissionCutoff',
                            courseLikeAssignment.courseLikeId
                          )
                            ? 'error'
                            : ''
                        "
                      >
                        <table-date
                          :dateTime="courseLikeAssignment.lateResponsePenaltyData?.submissionCutoff"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </s-page-block>

    <s-form-footer
      :cancelRoute="
        route('courses.assignments.show', {
          course: course.id,
          assignment: assignment.id,
        })
      "
      :formProcessing="form.processing"
      :formIsDirty="form.isDirty"
    />
  </form>

  <!-- Policy Modal -->
  <edit-assignment-policies-modal
    v-model:visible="showPolicyModal"
    :data="policyModalData"
    @confirm="applyPolicyChanges"
    :features="features"
  />

  <!-- Date Modal -->
  <edit-assignment-dates-modal
    v-model:visible="showDateModal"
    :configuration="dateModalConfiguration"
    confirmText="Apply"
    confirmIcon="floppy"
  ></edit-assignment-dates-modal>
</template>
<script setup lang="ts">
import {addDays, addHours, addMinutes, format} from 'date-fns';
import {getDifferenceInDaysHoursMinutes, normalizeDate} from '../../format/dates';
import {computed, defineProps, reactive, ref, watch} from 'vue';
import {Course} from '../../types/entities/course';
import {Assignment} from '../../types/entities/assignment';
import {CourseSection} from '../../types/entities/course-section';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SFormFooter from '../../design-system/SFormFooter.vue';
import STooltip from '../../design-system/STooltip.vue';
import SLabel from '../../design-system/SLabel.vue';
import SMultiSelectField from '../../design-system/SMultiSelectField.vue';
import SBtn from '../../design-system/SBtn.vue';
import SInputField from '../../design-system/SInputField.vue';
import {useForm} from '@inertiajs/vue3';
import TableDate from './Edit/TableDate.vue';
import EditAssignmentDatesModal from './Edit/EditAssignmentDatesModal.vue';
import EditAssignmentPoliciesModal, {PolicyModalData} from './Edit/EditAssignmentPoliciesModal.vue';
import {route} from 'ziggy-js';
import {useFlash} from '../../composables/useFlash';
import SButtonToggle from '../../design-system/SButtonToggle.vue';
import SBadge from '../../design-system/SBadge.vue';
import EditAssignmentHeader from '../../layouts/EditAssignmentHeader.vue';
import SIcon from '../../design-system/SIcon.vue';
import {useI18n} from 'vue-i18n';
import {LoadedTaskAssignmentDto} from '../../types/entities/task-assignment';
import {useLeavingFormConfirmation} from '../../composables/useLeavingFormConfirmation';
import CourseLikeAssignmentSettingsDto = App.DTOs.AssignmentSettings.Reads.CourseLikeAssignmentSettingsDto;
import CourseLikeAssignmentPermissionsDto = App.DTOs.AssignmentSettings.Reads.CourseLikeAssignmentPermissionsDto;
import AssignmentSettingsUpdateData = App.DTOs.AssignmentSettings.Writes.AssignmentSettingsUpdateData;

const flash = useFlash();

const props = defineProps<{
  errors: Record<string, string>;
  course: Course;
  assignment: Assignment;
  assignmentTasks: LoadedTaskAssignmentDto[];
  sections: CourseSection[];
  courseLikeAssignments: CourseLikeAssignmentSettingsDto[];
  courseLikePermissions: CourseLikeAssignmentPermissionsDto[];
  features: {
    assignmentTimelimits: boolean;
  };
  can: {
    update: boolean;
  };
}>();

const {t} = useI18n();

/* Form Data */
const form = useForm<FormModel>({
  assignmentSettingsByCourseLike: new Map(),
  reviewRequired: props.assignment.reviewRequired,
  tasksToReview: props.assignmentTasks
    .filter((assignmentTask) => assignmentTask.reviewRequired)
    .map((assignmentTask) => assignmentTask.id),
});

useLeavingFormConfirmation(form);

const allDisplayedCourseLikeAssignmentsUserCanAssignTo = computed<AssignmentSettingsFormRow[]>(
  () => {
    return filteredCourseLikeAssignments.value.filter((cla) => cla.userCanAssign);
  }
);

const firstDisplayedCourseLikeAssignmentUserCanAssignTo =
  computed<AssignmentSettingsFormRow | null>(() => {
    if (!allDisplayedCourseLikeAssignmentsUserCanAssignTo.value?.length) {
      return null;
    }

    return allDisplayedCourseLikeAssignmentsUserCanAssignTo.value[0];
  });

const firstDisplayedStartDateUserCanAssignTo = computed<Date>(() => {
  if (!firstDisplayedCourseLikeAssignmentUserCanAssignTo.value) {
    return new Date();
  }

  return firstDisplayedCourseLikeAssignmentUserCanAssignTo.value.startDate;
});

const firstDisplayedDueDateUserCanAssignTo = computed<Date>(() => {
  if (!firstDisplayedCourseLikeAssignmentUserCanAssignTo.value) {
    return new Date();
  }

  return firstDisplayedCourseLikeAssignmentUserCanAssignTo.value.dueDate;
});

// When the props.courseLikeAssignments change (after a form submission), we need to recompute our ref/form data to account for any new ids we may not have.
watch(
  () => props.courseLikeAssignments,
  () => {
    // We want to reload the form using the latest relevant data, but not if there are errors. The user's work could be paved over, in these cases.
    // NOTE: We watch the page errors instead of the form.errors, as there seems to be a delay in the form errors being updated which can cause the form to be reinitialized before the errors are set/nuke the user's work.
    if (!Object.keys(props.errors ?? {}).length) {
      initializeRefsAndFormData();
    }
  }
);

const allCourseLikes = [props.course, ...props.sections];
// This will give us a map that we can efficiently look up the course likes by their id.
const allCourseLikesMappedById: ReadonlyMap<number, Course | CourseSection> = new Map(
  allCourseLikes.map((courseLike) => [courseLike.id, courseLike])
);
const allCourseLikePermissionsMappedById: ReadonlyMap<number, CourseLikeAssignmentPermissionsDto> =
  new Map(props.courseLikePermissions.map((permission) => [permission.courseLikeId, permission]));

const userCanAssignToCourseLike = (courseLikeId: number): boolean => {
  const permission = allCourseLikePermissionsMappedById.get(courseLikeId);
  return permission?.canAssignAssignments ?? false;
};

const courseLikeMultiSelectOptions = ref<CourseLikeMultiselectOption[]>([]);
const selectedCourseLikes = ref<CourseLikeMultiselectOption[]>([]);
/**
 * This function will be called when the selected course likes change.
 * When this happens, we need to decide if we're toggling between the whole course, and selected sections. These are mutually exclusive options.
 * If the user selects the whole course, we should remove all the sections from the selected sections.
 * If the user selects a section, we should remove the course from the selected sections.
 * @param updatedSelectedOptions
 */
const processSelectedCourseLikesChange = (
  updatedSelectedOptions: CourseLikeMultiselectOption[] | null
) => {
  // If we don't have anything selected now, we need to empty the selected course likes array, and mark all existing course likes as deleted.
  if (!updatedSelectedOptions?.length) {
    selectedCourseLikes.value = [];
    form.assignmentSettingsByCourseLike.forEach((cla) => {
      cla.destroy = true;
    });
    return;
  }

  // Grab the latest item that was selected (which is added to the end of the array).
  const latestSelectedOption = updatedSelectedOptions[updatedSelectedOptions.length - 1];

  // If the user has selected the course, we should remove all the sections.
  if (latestSelectedOption.id === props.course.id) {
    selectedCourseLikes.value = updatedSelectedOptions.filter(
      (option) => option.id === props.course.id
    );
  } else {
    // If the user has selected a section, we should remove the course.
    selectedCourseLikes.value = updatedSelectedOptions.filter(
      (option) => option.id !== props.course.id
    );
  }

  // process possible additions
  for (const selectedCourseLike of selectedCourseLikes.value) {
    const cla = form.assignmentSettingsByCourseLike.get(selectedCourseLike.id);
    // If the course like is already in the form data, we need to make sure it's not marked as deleted.
    if (cla) {
      cla.destroy = false;
    } else {
      // Otherwise, add it to the map.
      form.assignmentSettingsByCourseLike.set(
        selectedCourseLike.id,
        courseLikeAssignmentSettingsFactory(selectedCourseLike)
      );
    }
  }

  // We also need to find any removed course likes and remove them from the form data.
  const removedCourseLikeIds = [...form.assignmentSettingsByCourseLike.keys()].filter(
    (id) => !selectedCourseLikes.value.some((option) => option.id === id)
  );

  for (const removedCourseLikeId of removedCourseLikeIds) {
    const cla = form.assignmentSettingsByCourseLike.get(removedCourseLikeId)!;
    cla.destroy = true;
  }
};

const getErrorMessage = (key: string, courseLikeId: number): string => {
  const errors = form.errors as any;
  return errors[`courseLikeAssignments.${courseLikeId}.${key}`] ?? '';
};

const hasError = (key: string, courseLikeId: number) => {
  return !!getErrorMessage(key, courseLikeId);
};

const noSectionsSelected = computed<boolean>(() => {
  return !selectedCourseLikes.value.length;
});

const userCanAssignToEntireCourse = computed<boolean>(() => {
  return userCanAssignToCourseLike(props.course.id);
});

const currentlyUnassignedOrAssignedToEntireCourse = computed<boolean>(() => {
  return !selectedCourseLikes.value.length || selectedCourseLikes.value[0].id === props.course.id;
});

const assignToAllCourseSections = () => {
  const allSections = courseLikeMultiSelectOptions.value.filter(
    (option) => option.id !== props.course.id
  );
  processSelectedCourseLikesChange(allSections);
};

const getDuration = (startDate: Date | null, endDate: Date | null): string => {
  if (!startDate || !endDate) {
    return '';
  }

  const {days, hours, minutes} = getDifferenceInDaysHoursMinutes(startDate, endDate);
  return `${days}d ${hours}h ${minutes}m`;
};

const processStartDateChanges = (
  result: DateModalResult,
  recordsToModify: AssignmentSettingsFormRow[]
) => {
  // We don't do relative changes to start dates right now, so this should not occur
  if (result.mode === 'relative') {
    return;
  }

  for (const record of recordsToModify) {
    // For now, set the start and visible date to the same date. This will change in the future if we split off visible date as a configurable field.
    record.startDate = new Date(result.date);
    record.visibleDate = new Date(result.date);
  }
};

const processDueDateChanges = (
  result: DateModalResult,
  recordsToModify: AssignmentSettingsFormRow[]
) => {
  for (const record of recordsToModify) {
    if (result.mode === 'relative') {
      let date = new Date(record.startDate);

      if (result.days) {
        date = addDays(date, result.days);
      }

      if (result.hours) {
        date = addHours(date, result.hours);
      }

      if (result.minutes) {
        date = addMinutes(date, result.minutes);
      }

      record.dueDate = date;
    } else {
      record.dueDate = new Date(result.date);
    }
  }
};

/* Policy Modal */
const showPolicyModal = ref<boolean>(false);
let applyPolicyModalChangesTo: AssignmentSettingsFormRow[] = [];
const policyModalData = reactive<PolicyModalData>({
  assignmentName: props.assignment.name,
  quizMode: {
    enabled: false,
    releaseDateType: null,
    fixedReleaseDate: {
      date: '',
      time: '',
    },
    relativeReleaseDate: {
      days: 0,
      hours: 0,
      minutes: 0,
    },
  },
  preventPastDueSubmissions: false,
  hideHelpItems: false,
  timelimit: {
    enabled: false,
    minutes: null,
  },
  lateResponsePenaltyPolicy: {
    enabled: false,
    penalty: {
      type: 'absolute',
      value: 0,
    },
    frequency: {
      type: 'hours',
      value: 1,
    },
    submissionCutoff: {
      cutoffDateType: null,
      fixedCutoffDate: {
        date: '',
        time: '',
      },
      relativeCutoffDate: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    },
  },
});

const openPolicyModal = (courseLikeAssignments: AssignmentSettingsFormRow[]) => {
  // There should always be data here on open, so this shouldn't happen if we've configured the template correctly.
  if (!courseLikeAssignments.length) {
    return;
  }

  // Ensure we track the assignments we will be applying changes to, on save.
  applyPolicyModalChangesTo = courseLikeAssignments;

  // We will use the first assignment to populate the form.
  const firstAssignment = courseLikeAssignments[0];

  /* Quiz Mode */
  /* Quiz mode release date */
  setQuizModeDateModalFields(firstAssignment);
  /* Timelimit */
  policyModalData.timelimit.enabled = firstAssignment.timelimitEnabled;
  policyModalData.timelimit.minutes = firstAssignment.timelimitInMinutes;
  /* Past Due Submissions */
  policyModalData.preventPastDueSubmissions = firstAssignment.preventPastDueSubmission;
  policyModalData.hideHelpItems = firstAssignment.hideHelpItems;
  /* Late Submission Penalty Policy */
  setLateResponsePenaltyPolicyModalFields(firstAssignment);

  showPolicyModal.value = true;
};

const setQuizModeDateModalFields = (courseLikeAssignment: AssignmentSettingsFormRow) => {
  const dateForForm = new Date(
    courseLikeAssignment.quizModeReleaseDate ?? courseLikeAssignment.dueDate
  );

  policyModalData.quizMode = {
    ...policyModalData.quizMode,
    enabled: courseLikeAssignment.quizMode,
    releaseDateType: courseLikeAssignment.quizModeReleaseDate ? 'fixed' : null,
    fixedReleaseDate: {
      date: format(dateForForm, 'yyyy-MM-dd'),
      time: format(dateForForm, 'HH:mm'),
    },
  };
};

const setLateResponsePenaltyPolicyModalFields = (
  courseLikeAssignment: AssignmentSettingsFormRow
) => {
  // If we have existing penalty data, load the values with it. Otherwise, fall back to defaults
  const penaltyData =
    courseLikeAssignment.lateResponsePenaltyData ??
    lateResponsePenaltyPolicyDataFactory(courseLikeAssignment);

  const cutoffDateForForm = new Date(penaltyData.submissionCutoff ?? courseLikeAssignment.dueDate);

  policyModalData.lateResponsePenaltyPolicy = {
    enabled: !!courseLikeAssignment.lateResponsePenaltyData,
    penalty: {
      type: penaltyData.penaltyType,
      value: penaltyData.penaltyValue,
    },
    frequency: {
      type: penaltyData.frequencyUnit,
      value: penaltyData.frequencyValue,
    },
    submissionCutoff: {
      // Map over any existing data for the relative values.
      ...policyModalData.lateResponsePenaltyPolicy.submissionCutoff,
      cutoffDateType: penaltyData.submissionCutoff ? 'fixed' : null,
      fixedCutoffDate: {
        date: format(new Date(cutoffDateForForm ?? courseLikeAssignment.dueDate), 'yyyy-MM-dd'),
        time: format(new Date(cutoffDateForForm ?? courseLikeAssignment.dueDate), 'HH:mm'),
      },
    },
  };
};

const extractQuizModeReleaseDateFromPolicyModal = (
  courseLikeAssignment: AssignmentSettingsFormRow
): Date | null => {
  const quizModeData = policyModalData.quizMode;
  if (quizModeData.releaseDateType === null || !quizModeData.enabled) {
    return null;
  }

  return quizModeData.releaseDateType === 'fixed'
    ? new Date(quizModeData.fixedReleaseDate.date + 'T' + quizModeData.fixedReleaseDate.time)
    : addDays(
        addHours(
          addMinutes(
            new Date(courseLikeAssignment.dueDate),
            quizModeData.relativeReleaseDate.minutes
          ),
          quizModeData.relativeReleaseDate.hours
        ),
        quizModeData.relativeReleaseDate.days
      );
};

const extractSubmissionCutoffFromPolicyModal = (
  courseLikeAssignment: AssignmentSettingsFormRow
): Date | null => {
  const submissionCutoff = policyModalData.lateResponsePenaltyPolicy.submissionCutoff;
  if (submissionCutoff.cutoffDateType === null) {
    return null;
  }

  return submissionCutoff.cutoffDateType === 'fixed'
    ? new Date(submissionCutoff.fixedCutoffDate.date + 'T' + submissionCutoff.fixedCutoffDate.time)
    : addDays(
        addHours(
          addMinutes(
            new Date(courseLikeAssignment.dueDate),
            submissionCutoff.relativeCutoffDate.minutes
          ),
          submissionCutoff.relativeCutoffDate.hours
        ),
        submissionCutoff.relativeCutoffDate.days
      );
};

const applyPolicyChanges = () => {
  for (const cla of applyPolicyModalChangesTo) {
    /* Quiz Mode */
    cla.quizMode = policyModalData.quizMode.enabled;
    /* Quiz Mode Release Date */
    cla.quizModeReleaseDate = extractQuizModeReleaseDateFromPolicyModal(cla);
    /* Past Due Submissions */
    cla.preventPastDueSubmission = policyModalData.preventPastDueSubmissions;
    /* Hide Help Items */
    cla.hideHelpItems = policyModalData.hideHelpItems;
    /* Timelimit */
    cla.timelimitEnabled = policyModalData.timelimit.enabled;
    cla.timelimitInMinutes = policyModalData.timelimit.minutes;
    /* Late Response Policy */
    applyLateResponsePolicyChangesFromPolicyModal(cla);
  }
};

const applyLateResponsePolicyChangesFromPolicyModal = (
  courseLikeAssignment: AssignmentSettingsFormRow
) => {
  const lateResponsePolicyFormData = policyModalData.lateResponsePenaltyPolicy;
  // If prevent past due submissions is enabled or the late response policy is toggled off, we should null out the late response policy.
  if (policyModalData.preventPastDueSubmissions || !lateResponsePolicyFormData.enabled) {
    courseLikeAssignment.lateResponsePenaltyData = null;
    return;
  }

  // Otherwise, we need to apply the changes to the late response policy.
  const lateResponsePolicyData =
    courseLikeAssignment.lateResponsePenaltyData ??
    lateResponsePenaltyPolicyDataFactory(courseLikeAssignment);

  lateResponsePolicyData.penaltyType = lateResponsePolicyFormData.penalty.type;
  lateResponsePolicyData.penaltyValue = lateResponsePolicyFormData.penalty.value;
  lateResponsePolicyData.frequencyUnit = lateResponsePolicyFormData.frequency.type;
  lateResponsePolicyData.frequencyValue = lateResponsePolicyFormData.frequency.value;
  lateResponsePolicyData.submissionCutoff =
    extractSubmissionCutoffFromPolicyModal(courseLikeAssignment);

  // Apply changes back onto the CLA.
  courseLikeAssignment.lateResponsePenaltyData = lateResponsePolicyData;
};

const showDateModal = ref<boolean>(false);
const dateModalConfiguration = ref<DateModalConfiguration>({
  mode: 'fixed',
  title: '',
  tagline: props.assignment.name,
  label: '',
  value: new Date(),
  recordsToModifyOnApply: [],
  callbackToExecuteOnApply: () => {},
});

const openDateModal = (configuration: DateModalConfiguration) => {
  dateModalConfiguration.value = configuration;
  showDateModal.value = true;
};

// Setup filter text for the course likes
const courseLikeNameFilterText = ref<string>('');
// Enable comma separated filters, each independently applied to the course likes names.
const courseLikeNameFilters = computed<string[]>(() => {
  if (!courseLikeNameFilterText.value) {
    return [];
  }

  return (
    courseLikeNameFilterText.value
      .split(',')
      .map((f) => f.trim().toLowerCase())
      // Remove the nulls.
      .filter((x) => !!x)
  );
});

// New computed property for filtered rows
const filteredCourseLikeAssignments = computed(() => {
  return [...form.assignmentSettingsByCourseLike.values()]
    .filter((cla) => {
      if (cla.destroy) {
        return false;
      }

      if (!courseLikeNameFilters.value.length) {
        return true;
      }

      return courseLikeNameFilters.value.some((filterItem) =>
        cla.courseLikeName.toLowerCase().includes(filterItem)
      );
    })
    .sort((a, b) => a.courseLikeName.localeCompare(b.courseLikeName));
});

const allRowsAreHiddenByFilter = computed<boolean>(() => {
  return (
    !!form.assignmentSettingsByCourseLike.size && filteredCourseLikeAssignments.value.length === 0
  );
});

const getCourseLikeMultiselectOptions = (): CourseLikeMultiselectOption[] => {
  // We need to check to see if this is assigned to the course, but the user doesn't have assigned permissions at the course level.
  // If this is the case, we should block them from modifying the assignment settings for this course.
  const currentlyAssignedToCourse = props.courseLikeAssignments.some(
    (cla) => cla.courseLikeId === props.course.id
  );
  const userCanAssignToCourse = userCanAssignToCourseLike(props.course.id);
  const userIsBlockedFromAssignmentChanges = currentlyAssignedToCourse && !userCanAssignToCourse;

  return allCourseLikes
    .map<CourseLikeMultiselectOption>((courseLike) => {
      const courseLikePermission = props.courseLikePermissions.find(
        (permission) => permission.courseLikeId === courseLike.id
      );

      return {
        id: courseLike.id,
        name: courseLike.name,
        dtype: courseLike.dtype,
        selectable:
          (!userIsBlockedFromAssignmentChanges && courseLikePermission?.canAssignAssignments) ??
          false,
      };
    })
    .sort(courseLikeSorter);
};

/**
 * This function will sort the course like options by dtype and then name.
 * course takes priority over coursesection.
 * @param a
 * @param b
 */
const courseLikeSorter = (a: CourseLikeMultiselectOption, b: CourseLikeMultiselectOption) => {
  if (a.dtype === 'course' && b.dtype === 'coursesection') {
    return -1;
  }

  if (a.dtype === 'coursesection' && b.dtype === 'course') {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

const getSelectedCourseLikeOptions = (): CourseLikeMultiselectOption[] => {
  return props.courseLikeAssignments.map((cla) => {
    return courseLikeMultiSelectOptions.value.find((option) => option.id === cla.courseLikeId)!;
  });
};

const courseLikeAssignmentSettingsFactory = (courseLike: CourseLike): AssignmentSettingsFormRow => {
  const templateCla = firstDisplayedCourseLikeAssignmentUserCanAssignTo.value;

  const startDate = templateCla?.startDate ?? new Date();
  const dueDate = templateCla?.dueDate ?? addDays(new Date(), 7);

  return {
    id: null,
    destroy: false,
    courseLikeId: courseLike.id,
    courseLikeName: courseLike.name,
    userCanAssign: userCanAssignToCourseLike(courseLike.id),
    startDate: new Date(startDate),
    dueDate: new Date(dueDate),
    visibleDate: new Date(startDate),
    pointValue: templateCla?.pointValue ?? 1,
    quizMode: templateCla?.quizMode ?? false,
    quizModeReleaseDate: templateCla?.quizModeReleaseDate
      ? new Date(templateCla.quizModeReleaseDate)
      : null,
    preventPastDueSubmission: templateCla?.preventPastDueSubmission ?? false,
    hideHelpItems: templateCla?.hideHelpItems ?? false,
    timelimitEnabled: false,
    timelimitInMinutes: null,
    lateResponsePenaltyData: templateCla?.lateResponsePenaltyData
      ? copyLateResponsePolicyForAnotherCourseLike(templateCla.lateResponsePenaltyData)
      : null,
  };
};

const copyLateResponsePolicyForAnotherCourseLike = (
  lateResponsePenalty: LateResponsePenaltyFormData
): LateResponsePenaltyFormData => {
  return {
    ...lateResponsePenalty,
    // Null out the ids for the new course like.
    id: null,
    courseLikeAssignmentId: null,
    submissionCutoff: lateResponsePenalty.submissionCutoff
      ? new Date(lateResponsePenalty.submissionCutoff)
      : null,
  };
};

const lateResponsePenaltyPolicyDataFactory = (
  courseLikeAssignment: AssignmentSettingsFormRow
): LateResponsePenaltyFormData => ({
  id: null,
  courseLikeAssignmentId: courseLikeAssignment.id,
  name: 'Late Response Policy',
  penaltyReason: '',
  penaltyType: 'relative',
  penaltyValue: 0,
  frequencyUnit: 'days',
  frequencyValue: 1,
  submissionCutoff: null,
});

const getFormCourseLikeAssignmentsFromDtos = (
  courseLikeAssignments: CourseLikeAssignmentSettingsDto[]
): AssignmentSettingsFormRow[] => {
  return courseLikeAssignments.map<AssignmentSettingsFormRow>((cla) => {
    // This should always return the CourseLike.
    const courseLike = allCourseLikesMappedById.get(cla.courseLikeId)!;

    return {
      id: cla.id,
      destroy: false,
      courseLikeId: courseLike.id,
      courseLikeName: courseLike.name,
      userCanAssign: userCanAssignToCourseLike(courseLike.id),
      startDate: normalizeDate(cla.startDate)!,
      dueDate: normalizeDate(cla.dueDate)!,
      visibleDate: normalizeDate(cla.visibleDate)!,
      pointValue: cla.pointValue,
      quizMode: cla.quizMode,
      quizModeReleaseDate: normalizeDate(cla.quizModeReleaseDate),
      preventPastDueSubmission: cla.preventPastDueSubmission,
      hideHelpItems: cla.hideHelpItems,
      timelimitEnabled: cla.timelimitEnabled,
      timelimitInMinutes: cla.timelimitInMinutes,
      lateResponsePenaltyData: cla.lateResponsePenaltyPolicy
        ? {
            ...cla.lateResponsePenaltyPolicy,
            submissionCutoff: normalizeDate(cla.lateResponsePenaltyPolicy.submissionCutoff),
          }
        : null,
    };
  });
};

const scrollToFirstError = () => {
  const errorElements = document.querySelectorAll('[data-error]');

  if (errorElements?.length) {
    errorElements[0].scrollIntoView({behavior: 'smooth', block: 'center'});
  }
};

/**
 * This function will take the form data and transform it into the DTOs that the backend expects.
 * Currently all commented out is it's not yet functional/ready.
 */
const submitForm = () => {
  flash?.value.dismiss('success');
  flash?.value.dismiss('error');

  form
    .transform((data: FormModel): AssignmentSettingsUpdateData => {
      const filteredFormRows = [...data.assignmentSettingsByCourseLike.values()]
        // Either the cla existed before, or it's new and not deleted.
        .filter((cla) => cla.id || !cla.destroy)
        // Only submit rows the user has rights to assign.
        .filter((cla) => cla.userCanAssign) as any[];

      return {
        reviewRequired: data.reviewRequired,
        tasksToReview: data.tasksToReview || [],
        courseLikeAssignments: filteredFormRows.reduce((cumulative, currentValue) => {
          const courseLikeId = currentValue.courseLikeId;
          cumulative[courseLikeId] = currentValue;
          return cumulative;
        }, {}),
      };
    })
    .post(
      route('courses.assignments.settings.update', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
      }),
      {
        only: ['assignment', 'courseLikeAssignments', 'errors', 'flash'],
        preserveScroll: true,
        preserveState: true,
        onError: (errors) => {
          flash?.value?.set('error', t('errors.settingsNotSaved'));
          setTimeout(scrollToFirstError);
        },
      }
    );
};

const initializeRefsAndFormData = () => {
  courseLikeMultiSelectOptions.value = getCourseLikeMultiselectOptions();
  selectedCourseLikes.value = getSelectedCourseLikeOptions();
  // Transform the DTOs into the form data map
  const formCourseLikeAssignments = getFormCourseLikeAssignmentsFromDtos(
    props.courseLikeAssignments
  );
  form.assignmentSettingsByCourseLike = new Map(
    formCourseLikeAssignments.map((cla) => [cla.courseLikeId, cla])
  );

  // Tell the form the current values are the defaults, and then reset its state.
  // This will ensure the isDirty value is cleaned.
  form.defaults();
  form.reset();
};

const isVisible = (courseLikeAssignment: AssignmentSettingsFormRow): boolean => {
  const today = new Date(Date.now());

  return courseLikeAssignment.startDate && today >= courseLikeAssignment.startDate;
};

initializeRefsAndFormData();
</script>
<style scoped>
.section-table-wrapper {
  @apply shrink overflow-auto relative w-full m-auto z-[1] rounded-lg shadow-card bg-white mt-1 mb-5;

  /* https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
  background: /* Shadow Cover RIGHT */
    linear-gradient(to left, white 1rem, rgba(255, 255, 255, 0)) center right,
    /* Shadow RIGHT */ linear-gradient(to left, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) center
      right,
    /* Shadow Cover LEFT */ linear-gradient(to right, white 1rem, rgba(255, 255, 255, 0)) center
      left,
    /* Shadow LEFT */ linear-gradient(to right, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) center
      left,
    /* Shadow Cover BOTTOM */ linear-gradient(to top, white 1rem, rgba(255, 255, 255, 0)) bottom
      center,
    /* Shadow BOTTOM */ linear-gradient(to top, rgba(27, 19, 53, 0.07), rgba(27, 19, 53, 0)) bottom
      center,
    white;

  background-repeat: no-repeat;
  background-size:
    3rem 100%,
    1rem 100%,
    3rem 100%,
    1rem 100%,
    100% 3rem,
    100% 1rem,
    100% 100%;
  background-attachment: local, scroll, local, scroll, local, scroll, local;
}

.section-table {
  @apply border-collapse border-spacing-0 w-full min-w-full max-w-full;

  thead {
    tr {
      th {
        @apply shadow-sm relative px-2 first:pl-4 last:pr-4 md:px-3 pt-2.5 pb-1 leading-[1.1] text-left bg-white/20 sticky top-0 z-[10] border-y first:border-l last:border-r border-gray-300/50 border-b-gray-300/40 first:rounded-tl-lg  last:rounded-tr-lg;
      }

      .th-group.small {
        @apply text-xs uppercase leading-[1.1] pt-3 text-gray-700/70;
      }

      .th-group {
        @apply bg-blue-100/30 border-blue-100/70;
      }

      .th-group.left {
        @apply pl-3;
      }

      .th-group.left {
        @apply border-l;
      }

      .th-group.right {
        @apply pr-3.5;
      }

      .th-group.right {
        @apply border-r;
      }

      .th-group.top-left {
        @apply rounded-tl-lg border-l;
      }

      .th-group.top-right {
        @apply rounded-tr-lg border-r;
      }
    }
  }

  tbody {
    tr {
      td {
        @apply text-left px-2 first:pl-4 last:pr-4 md:px-3 py-3 border-b border-gray-100;
      }

      .td-group {
        @apply border-blue-50 bg-blue-100/20;
      }

      .td-group.left {
        @apply pl-3 border-l;
        background-clip: padding-box;
      }

      .td-group.right {
        @apply pr-3.5;
      }

      &:nth-child(even) td {
        @apply bg-gray-300/10;
      }

      &:nth-child(even) td.td-group {
        @apply bg-blue-100/30;
      }

      &:last-child {
        td {
          @apply first:rounded-bl-lg last:rounded-br-lg border-b-0;
        }
      }
    }
  }
}

.section-time {
  @apply flex flex-col gap-0.5 whitespace-nowrap;
}

.section-time:not(.error) {
  @apply text-gray-700;
}

.section-time.error {
  @apply text-red-600;
}

.section-time-button {
  @apply underline underline-offset-2 decoration-transparent flex flex-col gap-0.5 whitespace-nowrap rounded-lg outline-transparent outline-4 outline-offset-4 focus:outline-blue-400 transition-all ease-out duration-100;
}

.section-time-button:not(:disabled) {
  @apply cursor-pointer hover:decoration-blue-200/50 active:decoration-blue-300 decoration-2 text-blue-700/80 hover:text-blue-400 active:text-blue-600;
}

.section-time-button:disabled {
  @apply cursor-not-allowed text-gray-600/80;
}

.section-edit-all-button {
  @apply underline underline-offset-2 decoration-transparent flex items-center gap-1 text-xs leading-tight uppercase tracking-slight font-black transition-all ease-out duration-100 py-1;
}

.section-edit-all-button:not(:disabled) {
  @apply hover:decoration-blue-200/50 active:decoration-blue-300 decoration-2 text-blue-700/80 hover:text-blue-400 active:text-blue-600;
}

.section-edit-all-button:disabled {
  @apply cursor-not-allowed text-gray-600/60;
}
</style>
<i18n>
{
  "en": {
    "taskReviewRequired": "Task Review Required",
    "tasksToReview": "Tasks To Review",
    "tasksShouldBeReviewed": "Tasks that should be reviewed in this assignment",
    "pleaseSelect": "Please Select",
    "tasks": "Tasks",
    "assignedTo": "Assigned to",
    "entireCourse": "Entire Course",
    "sections": "Sections",
    "assignIndividualSectionDates": "Assign Individual Section Dates",
    "sectionSettings": "Section Settings",
    "filterAssignmentSettings": "Filter Assignment Settings",
    "noSectionsSelected": "No sections selected",
    "noSectionsMatchFilter": "No sections match filter",
    "section": "Section",
    "editAll": "Edit All",
    "durationDash": "Duration -",
    "duration": "Duration",
    "policies": "Policies",
    "quizMode": "Quiz Mode",
    "gradeRelease": "Grade Release",
    "lateResponses": "Late Responses",
    "latePenalty": "Late Penalty",
    "startDateAllSections": "Start Date – All Sections",
    "New Start Date": "New Start Date",
    "durationAllSections": "Duration – All Sections",
    "dueDateAllSections": "Due Date – All Sections",
    "edit": "Edit",
    "noTimelimit": "There is no timelimit for this assignment.",
    "lateSubmissionsNotAllowed": "Late submissions are not allowed.",
    "lateSubmissionsAllowed": "Late submissions allowed.",
    "penaltyOf": "Penalty of {penaltyValue} marks for every {frequencyValue} {frequencyUnit} late.",
    "noLateResponsePenaltyApplied": "No Late Response Penalty Applied",
    "badges": {
      "visible": "Visible",
      "notVisible": "Not Visible"
    },
    "tooltips": {
      "visible": "Students in this section will be able to see this assignment.",
      "notVisible": "Students in this section will not be able to see this assignment.",
      "quizModeEnabled": "Quiz mode is enabled",
      "quizModeDisabled": "Quiz mode is disabled"
    },
    "errors": {
      "settingsNotSaved": "Assignment Settings were not saved."
    }
  },
  "fr": {
    "taskReviewRequired": "Révision de la tâche requise",
    "tasksToReview": "Tâches à réviser",
    "tasksShouldBeReviewed": "Tâches devant être révisées dans ce devoir",
    "pleaseSelect": "Veuillez sélectionner",
    "tasks": "Tâches",
    "assignedTo": "Attribué à",
    "entireCourse": "Cours complet",
    "sections": "Sections",
    "assignIndividualSectionDates": "Attribuer des dates individuelles à chaque section",
    "sectionSettings": "Paramètres de section",
    "filterAssignmentSettings": "Filtrer les paramètres des devoirs",
    "noSectionsSelected": "Aucune section sélectionnée",
    "noSectionsMatchFilter": "Aucune section ne correspond au filtre",
    "section": "Section",
    "editAll": "Modifier tout",
    "durationDash": "Durée -",
    "duration": "Durée",
    "policies": "Politiques",
    "quizMode": "Mode Quiz",
    "gradeRelease": "Date de publication",
    "lateResponses": "Soumissions tardives",
    "latePenalty": "Pénalité",
    "startDateAllSections": "Date de début – Toutes les sections",
    "New Start Date": "Nouvelle date de début",
    "durationAllSections": "Durée – Toutes les sections",
    "dueDateAllSections": "Date d'échéance – Toutes les sections",
    "edit": "Modifier",
    "noTimelimit": "Il n'y a pas de limite de temps pour ce devoir.",
    "lateSubmissionsNotAllowed": "Les soumissions tardives ne sont pas autorisées.",
    "lateSubmissionsAllowed": "Les soumissions tardives sont autorisées.",
    "penaltyOf": "Pénalité de {penaltyValue} points pour chaque {frequencyValue} {frequencyUnit} en retard.",
    "noLateResponsePenaltyApplied": "Aucune pénalité",
    "badges": {
      "visible": "Visible",
      "notVisible": "Non visible"
    },
    "tooltips": {
      "visible": "Les étudiants de cette section pourront voir ce devoir.",
      "notVisible": "Les étudiants de cette section ne pourront pas voir ce devoir.",
      "quizModeEnabled": "Le mode quiz est actif",
      "quizModeDisabled": "Le mode quiz est désactivé"
    },
    "errors": {
      "settingsNotSaved": "Les paramètres du devoir n'ont pas été enregistrés."
    }
  }
}
</i18n>
