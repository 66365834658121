import {NodeSpec, Schema} from 'prosemirror-model';
import {addListNodes} from 'prosemirror-schema-list';
import {paragraph} from '../../prosemirror-editor/schema/node_specs/paragraph';
import {blockquote} from '../../prosemirror-editor/schema/node_specs/blockquote';
import {horizontal_rule} from '../../prosemirror-editor/schema/node_specs/horizontal_rule';
import {heading} from '../../prosemirror-editor/schema/node_specs/heading';
import {code_block} from '../../prosemirror-editor/schema/node_specs/code_block';
import {text} from '../../prosemirror-editor/schema/node_specs/text';
import {hard_break} from '../../prosemirror-editor/schema/node_specs/hard_break';
import {math_inline} from '../../prosemirror-editor/schema/node_specs/math_inline';
import {math_display} from '../../prosemirror-editor/schema/node_specs/math_display';
import {link} from '../../prosemirror-editor/schema/mark_specs/link';
import {em} from '../../prosemirror-editor/schema/mark_specs/em';
import {strong} from '../../prosemirror-editor/schema/mark_specs/strong';
import {code} from '../../prosemirror-editor/schema/mark_specs/code';
import {variable} from './node_specs/variable-spec';

const nodes = {
  doc: {content: 'block*'},
  paragraph,
  blockquote,
  horizontal_rule,
  heading,
  code_block,
  // image, // Removed until we can make it work inside modals
  text,
  hard_break,
  math_inline,
  math_display,
  variable,
};

export const marks = {
  link,
  em,
  strong,
  code,
};

const baseSchema = new Schema({
  nodes,
  marks,
});

export const taskRichTextEditor = new Schema({
  nodes: addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block'),
  marks: baseSchema.spec.marks,
});
